import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { AppRoute } from "../../../const";
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './chistka-zubov.module.scss';

function Chistka() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Чистка зубов в Подольске – проверенный путь к здоровой улыбке</h1>
						<p>Профессиональная чистка зубов опытными стоматологами Дантист Плюс позволяет снизить риск возникновения кариеса, воспаления десен. С ее помощью можно быстро вернуть естественную белизну зубов, позаботиться о профилактике патологий зубочелюстной системы.</p>
						<p>Процедура чистки зубов в Подольске в стоматологической клинике «Дантист Плюс» быстрая и безболезненная, потому стоит воспользоваться ею прямо сейчас.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Для чего нужна чистка зубов в стоматологической клинике</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Постоянное употребление чая и кофе, курение неизбежно приводят к образованию зубного камня и зубного налета, а также к пожелтению эмали. Негативное воздействие осуществляет и не слишком качественная вода, которая течет из крана. Существенной проблемой в ряде случаев является и процесс потемнения зубов после удаления нерва, реконструкции зуба.</p>
							<p>Обычной чистки в домашних условиях бывает не достаточно. Даже если вы используете зубную нить и строго придерживаетесь правил поддержания гигиены полости рта, вы не застрахованы от потемнения эмали и отложения зубного камня. В результате улыбка выглядит неэстетично. Но это не главная проблема. Ведь зубной камень является прямой угрозой пародонтита, пародонтоза, кариеса.</p>
							<p>В связи с этим чистка зубов является профилактикой данных заболеваний. Проходить ее рекомендуется раз в полгода, что позволит обеспечить надлежащую гигиену полости рта. Необходима она также при назначении имплантации зубов и протезировании.</p>
						</div>
					</div>
					<h2 className={styles.title}>Какие есть методы чистки зубов в Дантист Плюс</h2>
					<p>Стоматологическая клиника «Дантист Плюс» предлагает профессиональную чистку зубов в Подольске такими методами:</p>
					<ul>
						<li><Link
							to={AppRoute.ULTRA_CHISTKA}
							className={styles.link}>
						Ультразвуковая чистка.</Link> Чистка зубов производится посредством скалера (специального ультразвукового аппарата). Процедура дает возможность быстро удалить даже крупный зубной камень, а также налет, возвращает естественный цвет зубам.</li>
						<li><Link
							to={AppRoute.AIR_FLOW}
							className={styles.link}>
						Снятие зубного камня и отбеливание Air Flow.</Link> Удаление зубного налета осуществляется путем воздействием воздушной струи и порошка. По окончании на эмаль зуба наносится защитный лак, придающий блеск. Процедура не травмирует десны и ткани зуба, не царапает эмаль, проходит быстро и безболезненно. Позволяет снять небольшие отложения налета от кофе, чая, иных пищевых красящих веществ.</li>
						<li>Полирование специальными пастами. Назначается как дополнительная процедура после удаления зубного камня для придания поверхности ровности, на которую наносятся абразивные пасты. Процедура проводится с использованием специального прибора с насадками, который полирует зубы, делая их гладкими. Полировка может производиться, как по здоровому зубу, так и по коронкам или пломбам, что дополнительно чистит их.</li>
						<li>Применение реминерализирующих препаратов. Способствует восстановлению минеральной части зуба, создает дополнительную защиту.</li>
					</ul>
					<h2 className={styles.title}>5 причин записаться на чистку зубов прямо сейчас</h2>
					<ol>
						<li>В нашей стоматологической клинике новое профессиональное оборудование и грамотные специалисты, что позволит вернуть белоснежную улыбку без боли и риска.</li>
						<li>У нас лучшие цены в Подольске на чистку зубов. Уточнить их вы всегда можете у нашего администратора.</li>
						<li>Вы обретете уверенность не только в собственном здоровье, но и собственной привлекательности. Ваша улыбка всегда будет открытой, а значит, искренней.</li>
						<li>Вы позаботитесь о профилактике кариеса и заболеваний десен. Помните, что любое промедление может грозить вам потерей зубов.</li>
						<li>Мы вместе подберем лучший вариант удаления зубного камня и отбеливания зубов, который будет эффективным и безболезненным.</li>
					</ol>
					<p>Запишитесь на консультацию к стоматологу уже сегодня – подарите себе сияющую белоснежную улыбку !</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Chistka;
