import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './parodontoz.module.scss';

function Parodontoz() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Лечение пародонтоза – позаботимся о ваших деснах</h1>
						<p>Специалисты клиники «Дантист Плюс» осуществляют лечение пародонтоза в Подольске. Многолетний опыт, высокотехнологичное  современное оборудование, эффективные медикаменты  —  все это позволяет предложить высокий уровень услуг. При этом мы стремимся, чтобы каждый мог позаботиться о здоровье своих зубов, поэтому предлагаем наилучшие цены на весь комплекс лечения.</p>
						<p>У нас есть современное оборудование, инструменты, эффективные медикаменты – так что лечение пройдет быстро и максимально безболезненно. Мы вылечим ваши десны и сбережем зубы. Главное – не затягивайте к обращению к врачу.</p>
						<p>Мы находимся в Подольске, но у нас часто бывают пациенты из Москвы. Это является лучшим подтверждением наших низких цен и неизменно высокого качества оказания стоматологических услуг.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Пародонтоз: разберемся в понятиях</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>При неверном уходе за полостью рта может появиться зубной камень, что становится одной из наиболее распространенных причин возникновения пародонтоза, то есть воспаления дёсен.</p>
							<p>Это может стать причиной потери зуба или развития иных заболеваний полости рта. Поэтому при появлении воспалительных процессов следует незамедлительно обратиться к  стоматологу.</p>
						</div>
					</div>
					<p>К иным причинам появления пародонтоза относятся:</p>
					<ul>
						<li>Аномальное развитие зубочелюстной системы</li>
						<li>Осложнения вегетососудистых органов, при атеросклерозе, гипертонии</li>
						<li>Нарушения работы сердечно-сосудистой и эндокринной систем</li>
						<li>Недостаток витаминов и микроорганизмов</li>
						<li>При неправильном пломбировании, ошибках установки имплантат</li>
						<li>Травмы челюсти</li>
					</ul>
					<p>Следует понимать, что пародонтоз лучше предупреждать, систематически посещая стоматолога и придерживая гигиены полости рта. Но если проблема уже заявила о себе, появилось воспаление десен, то необходимо провести лечение пародонтоза, чтобы избежать более серьезных проблем.</p>
					<p>К основным симптомам пародонтоза относится:</p>
					<ol>
						<li>Повышенная чувствительность зубов и бледное окрашивание десен. При этом кровоточивость может отсутствовать</li>
						<li>Зуд десен, из рестракция (отхождение от зуба)</li>
						<li>Обнажение шейки или корня зуба</li>
						<li>Неприятный запах изо рта</li>
						<li>Расшатывание зубов</li>
					</ol>
					<p>Проявление симптомов зависит от степени развития заболевания:</p>
					<ul>
						<li>Легкой</li>
						<li>Средней</li>
						<li>Тяжелой</li>
					</ul>
					<p>Степень пародонтоза определяет врач,который и назначает лечение</p>
					<h2 className={styles.title}>Этапы лечения пародонтоза</h2>
					<p>Любой прием в стоматологической клинике «Дантист Плюс» начинается с осмотра пациента для выявления проблем полости рта. После этого врач может назначить рентгенографию, чтобы более детально узнать состояние зубов, а затем уже принять решение о выборе лечения.</p>
					<p>Когда диагноз поставлен, стоматолог проводит чистку зубов, удаление зубного налета, камня. Далее пациенту назначают противовоспалительные препараты, витаминный комплекс, полоскания. Он может быть направлен на физиотерапевтические процедуры, что даст возможность активизировать кровообращение в деснах.</p>
					<p>При запущенных формах лечение пародонтоза может потребовать ортопедического или хирургического вмешательства. Но вы можете не переживать, ведь все процедуры мы делаем быстро и практически безболезненно, поэтому на какой бы стадии вы не обратились в нашу стоматологию, мы обязательно подберем вам эффективный комплекс восстановления надлежащего состояния полости рта.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Parodontoz;
