import React from 'react';
import Header from '../header/header';
import Promo from '../promo/promo';
import OrderBottom from '../order-bottom/order-bottom';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import styles from './promo-page.module.scss';

function PromoPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className='visually-hidden'>Акции и скидки в стоматологии «Дантист Плюс» в Подольске</h1>
        <Promo />
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default PromoPage;
