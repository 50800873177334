import React from 'react';
import cn from 'classnames';
import licenseFirst from '../../images/svid.jpg';
import licenseSecond from '../../images/licenziya1.jpg';
import licenseThird from '../../images/licenziya2.jpg';
import styles from './licenses.module.scss';

function Licenses() {
  return (
    <div className={cn(styles.licenses, 'container')}>
      <h2 className={styles.title}>Лицензии</h2>
      <ul className={styles.list}>
        <li className={styles.item}>
          <img className={styles.image} src={licenseFirst} alt='Свидетельство ИНН' width="370" height="570" />
        </li>
        <li className={styles.item}>
          <img className={styles.image} src={licenseSecond} alt='Выписка из реестра лицензий страница первая' width="370" height="570" />
        </li>
        <li className={styles.item}>
          <img className={styles.image} src={licenseThird} alt='Выписка из реестра лицензий страница вторая' width="370" height="570" />
        </li>
      </ul>
    </div >

  );
}

export default Licenses;
