import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './bugel.module.scss';

function Bugel() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Бюгельные зубные протезы - изготовление и цена в Подольске</h1>
						<p>Бюгельные протезы для зубов являются наиболее прогрессивными способами восстановления зубного ряда.</p>
						<p>Бюгельный протез представляет собой съемную стоматологическую конструкцию, отличающуюся от традиционных протезов тем, что жевательная нагрузка тут распределяется по всей поверхности челюсти, благодаря специальному металлическому дуговому каркасу, изготовленному из легкого и прочного сплава.</p>
						<p>Перед проведением различных видов стоматологических процедур, таких как протезированием и имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Бюгельные протезы имеют следующие преимущества:</h2>
						</div>
						<div className={styles.wrapper_text}>
							<ul>
								<li>Данное протезирование возможно при наличие всего нескольких прочных зубов</li>
								<li>Бюгельные протезы имеют высокую прочность и долговечность</li>
								<li>К бюгельным протезам легко привыкнуть, благодаря тому, что он не покрывает нёбо</li>
								<li>Исключается случаи нарушения дикции</li>
								<li>Бюгельные протезы можно не снимать каждую ночь</li>
								<li>Отсутствует риск возникновения стоматита</li>
								<li>При приеме пищи, человек не испытывает неудобств</li>
							</ul>
						</div>
					</div>
					<p>Бюгельные протезы делятся на: удерживаемые при помощи кламмеров, удерживаемые при помощи замковых креплений, имеющие телескопическую систему фиксацию.</p>
					<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление бюгельных зубных протезов в кротчайшие сроки. Наши протезы - это долговечный способ забыть о проблемах с зубами!</p>
					<p>Вам понравится стоимость бюгельных зубных протезов, ведь мы исходим из конкретной ситуации. Но при любом варианте лечения, Вы останетесь довольны ценой и качеством.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
					<p>С внедрением новейших современных технологий в стоматологии, изменился подход в лечении зубов. Лечение зубов без боли - это реальность!</p>
					<p>Красивые здоровые зубы - это одна из составных частей здоровья организма в целом. Преодолейте свою лень, боязнь стоматологов, занятость и прочие причины и вовремя обратиться к нам в клинику «Дантист Плюс» за консультацией и квалифицированным лечением зубов.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Bugel;
