import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './implantaciya.module.scss';

function Implantaciya() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Имплантация зубов</h1>
						<p>Имплантация зубов - это хирургическая операция, в процессе которой стоматолог устанавливает в костную ткань имплантат, выполняющий роль фундамента для коронки или протеза. Данная манипуляция восстанавливает жевательную функцию и эстетику улыбки, возвращая пациентам уверенность в себе!</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Имплантация зубов - современные подходы</h2>
						</div>
						<div className={styles.wrapper_text}>
					<p><b>Импланты MIS</b> - это разработка израильской фирмы, начавшей свою работу в 1995 году. Изготавливаются данные импланты из высококачественного титана 23 класса. Осложнения после операции минимальны, а вариантов конструкций много, что позволяет устанавливать их в костную ткань разного объема и плотности.</p>
					<p><b>Импланты ASTRA TECH</b> - это шведское качество конструкций для имплантации и протезирования. Данные импланты считаются одними из лучших в мире! Они изготавливаются из титана 4 класса, без примесей алюминия и ванадия, что позволяет конструкции лучше приживаться, не вызывая аллергии и не нанося вред для организма. Преимущества ASTRA TECH: длительная эксплуатация, гарантия приживления, сохранение костной ткани по всей длине, эстетика и многое другое.</p>
						</div>
					</div>
					<p><b>Импланты Альфа Био</b> - это новая израильская разработка, обладающая рядом преимуществ, таких как: простая конструкция, легкость в установке и длительная эксплуатация. Alpha Bio имеют уникальную форму, состав сплава и материал покрытия, что придает изделию естественность, а установка занимает не более получаса!</p>
					<p><b>Коронка E-max</b> - представляет собой безметалловую зубную конструкцию нового поколения, производства Ivoclar-Vivadent, Лихтенштейн. Отсутствие металлического каркаса в данном протезе не критично, так как его его функции выполняет основа из дисиликата лития, это обеспечивает прочность и устойчивость к деформированию.</p>
					<p><b>Коронка Prettau</b> - это несъемная цельнолитая ортопедическая конструкция, выполненная из диоксида циркония и частично-облицованная керамикой или без нее. Основные преимущества коронок Prettau: прочность, эстетика, естественные оттенки, длительная эксплуатация, точность посадки, стоимость и т.д.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Implantaciya;
