import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import styles from './service.module.scss';

function Service({ service }) {

  const { tabName, title, text, link, image } = service;

  return (
    <div className={styles.item}>
      <Link to={link}><img className={styles.image} src={image} alt={`${tabName}`} width="370" height="250" /></Link>
      <Link to={link} className={styles.link_title}><h3 className={styles.title}>{title}</h3></Link>
      <p className={styles.text}>{text}</p>
      <Link to={link} className={styles.link}>Подробнее</Link>
    </div>
  );
}

Service.propTypes = {
  service: PropTypes.shape({
    tabName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
};

export default Service;
