import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './most-protez.module.scss';

function Mostprotez() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Мостовидные зубные протезы - изготовление и цена в Подольске</h1>
						<p>Мостовидные зубные протезы представляют собой конструкцию, состоящую из двух коронок, между которыми располагаются искусственные зубы. По форме такой зубной протез напоминает мост.</p>
						<p>Перед проведением протезирования зубов, люминирования или имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Преимущества мостовидного протеза:</h2>
						</div>
						<div className={styles.wrapper_text}>
							<ul>
								<li>зубы можно восстановить за одно посещение</li>
								<li>препарируется небольшой участок эмали здорового зуба для прикрепления стекловолоконной ленты</li>
								<li>стоимость мостовидного протеза примерно вдвое ниже, чем стоимость протеза из металлокерамики;
обточка и депульпирование здоровых зубов не требуется</li>
							</ul>
							<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление мостовидных зубных протезов в кротчайшие сроки. Это отличный способ забыть о проблемах с зубами!</p>
							<p>Вам понравится цена мостовидного зубного протеза, ведь мы исходим из конкретной ситуации. Вы останетесь довольны стоимостью и качеством.</p>
						</div>
					</div>
					<p className={styles.bold_text}>Показание к установке мостовидного протеза:</p>
					<ul>
						<li>отсутствие одного или нескольких зубов</li>
						<li>дефекты зубов и зубного ряда, если возможность устранить их другими способами отсутствует</li>
						<li>необходимость замены мостовидных протезов</li>
						<li>дефект, связанный с отсутствием 2 зубов</li>
					</ul>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Mostprotez;
