import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './stomatit.module.scss';

function Stomatit() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Лечение стоматита – серьезный подход к нешуточной проблеме</h1>
						<p>Стоматологическая клиника «Дантист Плюс» осуществляет лечение стоматита на различных стадиях заболевания. Мы готовы оказать помощь каждому, кто обратится к нам со своей проблемой, ведь у нас есть всё необходимое оборудование, медикаменты, а опыт наших врачей позволит найти выход даже в самой сложной ситуации.</p>
						<p>У нас лучшие цены в Подольске, поэтому число наших постоянных пациентов растет. И это не потому, что мы плохо лечим, а потому, что делаем всё быстро и безболезненно. К нам не боятся приходить на прием даже тогда, когда зубная боль не беспокоит. Мы приглашаем и вас для лечения стоматита или в целях его профилактики пройти осмотр у врача стоматолога.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Признаки стоматита</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Лечение стоматита назначается после полного осмотра полости рта, а также опроса пациента на предмет жалоб, с которыми он обращается в нашу клинику.</p>
							<p>При этом врач стоматолог может выявить различные признаки стоматита в зависимости от протекающей формы болезни, а после этого назначить лечение.</p>
							<p>Стоматит может протекать в афтозной, катаральной и язвенной форме.</p>
						</div>
					</div>
					<p>При афтозной форме наблюдается:</p>
					<ul>
						<li>Изменение цвета слизистой.  Она становится сероватая или белесая</li>
						<li>Появление прозрачных пузырьков</li>
						<li>Образование налета на деснах и слизистой</li>
						<li>Увеличение лимфатических узлов и появление температуры</li>
					</ul>
					<p>При катаральном стоматите наблюдается:</p>
					<ul>
						<li>Отечность слизистой</li>
						<li>Покраснение</li>
					</ul>
					<p>При язвенной формы стоматита наблюдается:</p>
					<ul>
						<li>Поражение язвами мягких тканей</li>
						<li>Частые головные боли</li>
						<li>Слабость на фоне повышенного слюноотделения</li>
					</ul>
					<p className={styles.bold_text}>Когда следует обращаться в стоматологии для лечения стоматита?</p>
					<p>Визит к стоматологу должен быть систематическим, что позволит предупредить проблемы с полостью рта. Как правило, рекомендуется посещать стоматологический кабинет не реже одного раза в полгода. Если вы не придерживаетесь данного правила, то на прием к стоматологу следует незамедлительно записаться случае, когда вы обнаружили изменения в полости рта, появление болезненность язвочек, пузырьков, царапин, которые затрудняют прием пищи, вызывают жжение и иные болезненные ощущения.</p>
					<p className={styles.bold_text}>Что приводит к стоматиту?</p>
					<p>Стоматит могут вызвать осложнения вирусных заболеваний,  невернае гигиена полости рта, прием через мерно горячих или холодных напитков,  заболевания сердечно-сосудистой эндокринной системой, а также болезни ЖКТ,  механические повреждения в полости рта,  постоянные стрессы, нехватка витаминов, сезонная аллергия. При этом лечение стоматита будет направлено на устранение данных причин.</p>
					<h2 className={styles.title}>Виды стоматита</h2>
					<p>Для назначения лечения врач-стоматолог изначально определяет вид стоматита, который может быть:</p>
					<ul>
						<li>Острым или хроническим. Острая форма предполагает первичное образование болезни.  При этом следует понимать, что для лечения необходимо обращаться сразу же в стоматологическую клинику во избежание перетекания болезни в хронический тип. При острой форме стоматита проявляются первичные признаки болезни, которые ранее не беспокоили пациента. Хронический стоматит может перейти в язвенную форму.  Поэтому во избежание рецидивов заболевания помимо лечения врач стоматолог назначает профилактические меры безопасности.</li>
						<li>Аллергический.  Может быть вызван приемом препаратов, потреблением продуктов питания, в следствие реакции на шерсть животных, цветение, плесень. У больного появляется зуд, сыпь, отек и воспаление слизистой. К аллергическому стоматиту также относится токсический,  который может быть вызван отравлением организма вредными соединениями, перенасыщением теми или иными веществами или препаратами.</li>
						<li>Травматический.  Развивается вследствие повреждения мягких тканей или оболочек слизистой,  на которой в результате появляется язвы высыпания, отек.</li>
						<li>Инфекционный. Возникает вследствие попадания грибков, бактерий, и вирусов в микротрещинки на слизистой.  Подразделяется на вирусный, бактериальный, кандидозный.  Проявляется в виде появления на слизистой гнойных выделений, налета на языке, вызывает общее ухудшение состояния, отек и температуру.</li>
						<li>Атрофический. Появляется из-за плохого питания,  гиповитаминоза, соматических заболеваний. К образованию данного вида стоматита более склонны люди преклонного возраста.  При этом во рту наблюдается образование папиллом, изменяется цвет слизистой.</li>
					</ul>
					<h2 className={styles.title}>Как происходит лечение стоматита</h2>
					<p>Обратившись для лечения стоматита в нашу стоматологическую клинику в Подольске, пациент сразу попадаете на прием к опытному врачу, которые проводит исследование полости рта,  выявляет причины появившегося заболевания, после чего назначают лечение: различные виды терапии на устранение болевой симптоматики, снятие отека и воспаления пораженной слизистой.</p>
					<p>Кроме того, врач прописывает полоскания, гели, мази, которыми следует обрабатывать ротовую полость для последующего лечения стоматита, а также во избежание его повторного проявления.  Для эффективного лечения также используется терапия на восстановление иммунитета, снижение стрессовых факторов, общее укрепление организма. Вы получаете консультацию специалиста, который расскажет о том, как правильно ухаживать за полостью рта вашем конкретном случае.</p>
					<p>Помните о том, что своевременное обращение стоматологу позволит избежать перетекания стоматита в хроническую форму.  Поэтому не откладывайте свой визит в нашу клинику в Подольске. Звоните нам прямо сейчас, если вас беспокоит состояние вашей полости рта.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Stomatit;
