import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './udalenie.module.scss';

function Udalenie() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Удаление зубов</h1>
						<p>Имплантация зубов - это хирургическая операция, в процессе которой стоматолог устанавливает в костную ткань имплантат, выполняющий роль фундамента для коронки или протеза. Данная манипуляция восстанавливает жевательную функцию и эстетику улыбки, возвращая пациентам уверенность в себе!</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Показания к удалению зубов</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>В плановом порядке зуб может быть удалён по следующим показаниям:</p>
							<ul>
								<li>Зубы с непроходимыми корневыми каналами с хроническими околокорневыми периодонтитами, кистами, особенно осложненные гайморитами, невралгиями.</li>
								<li>Невозможность сохранить разрушенный зуб.</li>
								<li>Подвижность зубов третьей и четвёртой степени при пародонтите.</li>
								<li>Атипичное положение зубов мудрости или других зубов, которые травмируют слизистую оболочку, мешают приему пищи и функции речи.</li>
							</ul>
						</div>
					</div>
					<ul className={styles.list}>
						<li>Сверхкомплектные и ретенированные зубы, вызывающие боль или воспалительные процессы (ретенционные кисты).</li>
						<li>Механическое повреждение зуба (переломы корня).</li>
						<li>Зубы на линии переломов челюстей.</li>
						<li>Опухоли челюсти.</li>
						<li>При проведении ортодонтического лечения (ретенция).</li>
						<li>При проведении ортопедического лечения (одиночные зубы, которые мешают стабилизации протеза).</li>
					</ul>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Udalenie;
