import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './lyuminiry.module.scss';

function Lyuminiry() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Люминиры - цены и установка в Подольске</h1>
						<p>Люминиры - это тоненькие накладки на зубы, выполненные из фарфора. Они тоньше виниров. Их толщина составляет 0,2 мм.</p>
						<p>Люминиры изготавливаются при помощи моделирующих технологий, что позволяет получить накладки максимально соответствующие по форме и цвету зубам.</p>
						<p>Перед проведением различных видов стоматологических процедур, таких как протезированием и имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Люминиры намного прочнее виниров, что исключает возможность появления на их поверхности микротрещин.</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Стоматологическая клиника "Дантист плюс" выполняет изготовление люминированных накладок в кротчайшие сроки. Это долговечный способ забыть о проблемах с зубами!</p>
							<p>Вам понравится стоимость зубной накладки, ведь мы исходим из конкретной ситуации. Вы останетесь довольны ценой и качеством.</p>
							<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
						</div>
					</div>
					<p>Люминиры долго служат, от двадцати и более лет. А еще один немаловажный плюс люминиров, это то, что их можно снимать.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Lyuminiry;
