import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './neilon.module.scss';

function Neilon() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Нейлоновые зубные протезы - изготовление и цена в Подольске</h1>
						<p>Нейлоновые зубные протезы преобрели популярность в стоматологических клиниках совсем недавно, но уже успели обрести своих сторонников.</p>
						<p>Раньше нейлоновые протезы, изготовленные из твердой пластмассы, использовали только в виде временных конструкций, но как только появились их гибкие аналоги, использование стало постоянным. Материалом, для изготовления протезов, служит дентальный нейлон, предназначенный исключительно для зубного протезирования.</p>
						<p>Перед проведением различных видов стоматологических процедур, таких как протезированием и имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<p>Гибкие нейлоновые протезы значительно отличаются от протезов с металлической конструкцией или протезов, выполненных из твердой пластмассы. Протезы старых образцов с металлическими частями постоянно натирали десны, создавая дискомфорт их обладателям. Гибкие нейлоновые протезы мягкие и легкие, благодаря чему удобно фиксируются в полости рта.</p>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Преимущества нейлоновых протезов</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление мостовидных зубных протезов в кротчайшие сроки. Это отличный способ забыть о проблемах с зубами!</p>
							<p>Основным плюсом гибких нейлоновых протезов является то, что отпадает необходимость в обточке здоровых зубов.
Людям с аллергией на металл нейлоновые протезы позволят исключить аллергические реакции.
Гибкие нейлоновые протезы возможно использовать и тем, кто страдает пародонтитом.</p>
						</div>
					</div>
					<p>Вам понравится стоимость нейлоновых зубных протезов, ведь мы исходим из конкретной ситуации. Но при любом варианте лечения, Вы останетесь довольны.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
					<p>С внедрением новейших современных технологий в стоматологии, изменился подход в лечении зубов. Лечение зубов без боли - это реальность!</p>
					<p>Красивые здоровые зубы - это одна из составных частей здоровья организма в целом. Преодолейте свою лень, боязнь стоматологов, занятость и прочие причины и вовремя обратиться к нам в клинику «Дантист Плюс» за консультацией и квалифицированным лечением зубов.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Neilon;
