import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from '../scroll-to-top-all/scroll-to-top-all';
import MainPage from '../main-page/main-page';
import AdultPage from '../adult-page/adult-page';
import KidsPage from '../kids-page/kids-page';
import SpetsialistyPage from '../spetsialisty-page/spetsialisty-page';
import PricePage from '../price-page/price-page';
import PromoPage from '../promo-page/promo-page';
import OrderPage from '../order-page/order-page';
import ReviewsPage from '../reviews-page/reviews-page';
import ContactsPage from '../contacts-page/contacts-page';
import LechenieMicro from '../uslugi/lechenie-micro/lechenie-micro';
import LechenieZubov from '../uslugi/lechenie-zubov/lechenie-zubov';
import Protez from '../uslugi/protez/protez';
import Microprotez from '../uslugi/microprotez/microprotez';
import Mostprotez from '../uslugi/most-protez/most-protez';
import Neilon from '../uslugi/neilon/neilon';
import Bugel from '../uslugi/bugel/bugel';
import Koronki from '../uslugi/koronki-iz-oksida/koronki-iz-oksida';
import Lyuminiry from '../uslugi/lyuminiry/lyuminiry';
import Vkladka from '../uslugi/vkladka/vkladka';
import KultVkladka from '../uslugi/kultevaya-vkladka/kultevaya-vkladka';
import Chistka from '../uslugi/chistka-zubov/chistka-zubov';
import Otbelivanie from '../uslugi/otbelivanie-zubov/otbelivanie-zubov';
import UltraChistka from '../uslugi/ultrazvukovaya-chistka/ultrazvukovaya-chistka';
import AirFlow from '../uslugi/air-flow/air-flow';
import Karies from '../uslugi/karies/karies';
import Parodontoz from '../uslugi/parodontoz/parodontoz';
import Parodontit from '../uslugi/parodontit/parodontit';
import LechenieDesen from '../uslugi/lechenie-desen/lechenie-desen';
import Stomatit from '../uslugi/stomatit/stomatit';
import Breketi from '../uslugi/breketi/breketi';
import Kista from '../uslugi/kista/kista';
import Implantaciya from '../uslugi/implantaciya/implantaciya';
import Udalenie from '../uslugi/udalenie/udalenie';
import Doctor from '../doctor/doctor';
import { doctors } from '../../const';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <ScrollToTop />
          <MainPage />
        </Route>
        <Route path="/adult" exact>
          <AdultPage />
        </Route>
        <Route path="/detskaya-stomatologiya" exact>
          <ScrollToTop />
          <KidsPage />
        </Route>
        <Route path="/spetsialisty" exact>
          <ScrollToTop />
          <SpetsialistyPage />
        </Route>
        <Route path="/prajsy" exact>
          <ScrollToTop />
          <PricePage />
        </Route>
        <Route path="/zapisatsya" exact>
          <ScrollToTop />
          <OrderPage />
        </Route>
        <Route path="/kontakty" exact>
          <ScrollToTop />
          <ContactsPage />
        </Route>
        <Route path="/skidki-i-aktsii" exact>
          <ScrollToTop />
          <PromoPage />
        </Route>
        <Route path="/otzyvy" exact>
          <ScrollToTop />
          <ReviewsPage />
        </Route>
        <Route path="/lechenie-pod-mikroskopom" exact>
          <ScrollToTop />
          <LechenieMicro />
        </Route>
        <Route path="/lechenie-zubov" exact>
          <ScrollToTop />
          <LechenieZubov />
        </Route>
        <Route path="/protezirovanie-zubov" exact>
          <ScrollToTop />
          <Protez />
        </Route>
        <Route path="/mikroprotezirovanie-zubov" exact>
          <ScrollToTop />
          <Microprotez />
        </Route>
        <Route path="/mostovidnye-zubnie-protezy" exact>
          <ScrollToTop />
          <Mostprotez />
        </Route>
        <Route path="/nejlonovye-zubnye-protezy" exact>
          <ScrollToTop />
          <Neilon />
        </Route>
        <Route path="/byugelnye-zubnye-protezy" exact>
          <ScrollToTop />
          <Bugel />
        </Route>
        <Route path="/koronki-iz-oksida-tsirkoniya" exact>
          <ScrollToTop />
          <Koronki />
        </Route>
        <Route path="/lyuminiry" exact>
          <ScrollToTop />
          <Lyuminiry />
        </Route>
        <Route path="/vkladka-keramicheskaya-izgotovlenie" exact>
          <ScrollToTop />
          <Vkladka />
        </Route>
        <Route path="/kultevaya-vkladka" exact>
          <ScrollToTop />
          <KultVkladka />
        </Route>
        <Route path="/chistka-zubov" exact>
          <ScrollToTop />
          <Chistka />
        </Route>
        <Route path="/otbelivanie-zubov" exact>
          <ScrollToTop />
          <Otbelivanie />
        </Route>
        <Route path="/ultrazvukovaya-chistka" exact>
          <ScrollToTop />
          <UltraChistka />
        </Route>
        <Route path="/air-flow" exact>
          <ScrollToTop />
          <AirFlow />
        </Route>
        <Route path="/karies" exact>
          <ScrollToTop />
          <Karies />
        </Route>
        <Route path="/lechenie-parodontoza" exact>
          <ScrollToTop />
          <Parodontoz />
        </Route>
        <Route path="/parodontit" exact>
          <ScrollToTop />
          <Parodontit />
        </Route>
        <Route path="/lechenie-desen" exact>
          <ScrollToTop />
          <LechenieDesen />
        </Route>
        <Route path="/stomatit-lechenie" exact>
          <ScrollToTop />
          <Stomatit />
        </Route>
        <Route path="/brekety-zubov-tsena-ustanovka" exact>
          <ScrollToTop />
          <Breketi />
        </Route>
        <Route path="/kista-zuba" exact>
          <ScrollToTop />
          <Kista />
        </Route>
        <Route path="/implantaciya" exact>
          <ScrollToTop />
          <Implantaciya />
        </Route>
        <Route path="/udalenie" exact>
          <ScrollToTop />
          <Udalenie />
        </Route>
        <Route path="/ponizova" exact>
          <Doctor doctor={doctors[0]} />
        </Route>
        <Route path="/kadzaeva" exact>
          <Doctor doctor={doctors[1]} />
        </Route>
        <Route path="/levitanskii" exact>
          <Doctor doctor={doctors[2]} />
        </Route>
        <Route path="/lukyanchikov" exact>
          <Doctor doctor={doctors[3]} />
        </Route>
        <Route path="/sivirchukov" exact>
          <Doctor doctor={doctors[4]} />
        </Route>
        <Route path="/matchanova" exact>
          <Doctor doctor={doctors[5]} />
        </Route>
        <Route path="/budei" exact>
          <Doctor doctor={doctors[6]} />
        </Route>
        < Redirect from="/index.html" to="/" />
        < Redirect from="/adult.html" to="/adult" />
        < Redirect from="/detskaya-stomatologiya.html" to="/detskaya-stomatologiya" />
        < Redirect from="/spetsialisty.html" to="/spetsialisty" />
        < Redirect from="/prajsy.html" to="/prajsy" />
        < Redirect from="/zapisatsya.html" to="/zapisatsya" />
        < Redirect from="/kontakty.html" to="/kontakty" />
        < Redirect from="/skidki-i-aktsii.html" to="/skidki-i-aktsii" />
        < Redirect from="/otzyvy.html" to="/otzyvy" />
        < Redirect from="/lechenie-pod-mikroskopom.html" to="/lechenie-pod-mikroskopom" />
        < Redirect from="/2014-12-04-11-53-23.html" to="/lechenie-zubov" />
        < Redirect from="/protezirovanie-zubov.html" to="/protezirovanie-zubov" />
        < Redirect from="/mikroprotezirovanie-zubov.html" to="/mikroprotezirovanie-zubov" />
        < Redirect from="/mostovidnye-zubnie-protezy.html" to="/mostovidnye-zubnie-protezy" />
        < Redirect from="/nejlonovye-zubnye-protezy.html" to="/nejlonovye-zubnye-protezy" />
        < Redirect from="/byugelnye-zubnye-protezy.html" to="/byugelnye-zubnye-protezy" />
        < Redirect from="/koronki-iz-oksida-tsirkoniya.html" to="/koronki-iz-oksida-tsirkoniya" />
        < Redirect from="/lyuminiry.html" to="/lyuminiry" />
        < Redirect from="/vkladka-keramicheskaya-izgotovlenie.html" to="/vkladka-keramicheskaya-izgotovlenie" />
        < Redirect from="/kultevaya-vkladka.html" to="/kultevaya-vkladka" />
        < Redirect from="/chistka-zubov.html" to="/chistka-zubov" />
        < Redirect from="/otbelivanie-zubov.html" to="/otbelivanie-zubov" />
        < Redirect from="/ultrazvukovaya-chistka.html" to="/ultrazvukovaya-chistka" />
        < Redirect from="/air-flow.html" to="/air-flow" />
        < Redirect from="/2014-12-04-12-00-54.html" to="/karies" />
        < Redirect from="/karies.html" to="/karies" />
        < Redirect from="/lechenie-parodontoza.html" to="/lechenie-parodontoza" />
        < Redirect from="/parodontit.html" to="/parodontit" />
        < Redirect from="/lechenie-desen.html" to="/lechenie-desen" />
        < Redirect from="/stomatit-lechenie.html" to="/stomatit-lechenie" />
        < Redirect from="/brekety-zubov-tsena-ustanovka.html" to="/brekety-zubov-tsena-ustanovka" />
        < Redirect from="/kista-zuba.html" to="/kista-zuba" />
        < Redirect from="/implantaciya.html" to="/implantaciya" />
        < Redirect from="/udalenie.html" to="/udalenie" />
        < Redirect from="/9-terapiya.html" to="/adult#terapiya" />
        < Redirect from="/15-ortopediya.html" to="/adult#ortopediya" />
        < Redirect from="/binokulyary.html" to="/" />
        < Redirect from="/component/content/?id=57&Itemid=208.html" to="/" />
        < Redirect from="/18-informatsiya/2-uslugi-stomatologii.html" to="/adult" />
        < Redirect from="/10-polezno-znat.html" to="/" />
        < Redirect from="/17-khirurgiya.html" to="/adult#khirurgiya" />
        < Redirect from="/12-parodontologiya.html" to="/adult#paradontologiya" />
        < Redirect from="/periodontit.html" to="/" />
        < Redirect from="/lechenie-klinovidnogo-defekta-zubov.html" to="/" />
        < Redirect from="/udalenie-zubov.html" to="/udalenie" />
        < Redirect from="/endodontiya.html" to="/" />
        < Redirect from="/perelechivanie-kanalov.html" to="/" />
        < Redirect from="/component/content/article/12-parodontologiya/39-implantatsiya-zubov.html?Itemid=251.html" to="/" />
        < Redirect from="/component/content/?id=41&Itemid=292.html" to="/" />
        < Redirect from="/index.php?option=com_content&view=article&id=41&Itemid=292.html" to="/" />
        < Redirect from="/18-informatsiya.html" to="/adult" />
        < Redirect from="/implantatsiya-zubov.html" to="/implantaciya" />
        < Redirect from="/pulpit.html" to="/" />
        < Redirect from="/11-gigiena.html" to="/" />
        < Redirect from="/14-ortodontiya.html" to="/" />
        < Redirect from="/16-drugie-uslugi.html" to="/adult" />
        < Redirect from="/8-skidki-i-aktsii.html" to="/skidki-i-aktsii" />
        < Redirect from="/component/content/category/8-terapiya-kategoriya.html.html" to="/skidki-i-aktsii" />
        < Redirect from="/index.php?option=com_content&view=article&id=29&Itemid=137.html" to="/karies" />
        < Redirect from="/index.php?option=com_content&view=article&id=33&Itemid=168.html" to="/lechenie-parodontoza" />
        < Redirect from="lechenie-zubov.html?ysclid=lc2ln9qscx80213435" to="/lechenie-zubov" />
        < Redirect from="/index.php?option=com_content&view=article&id=41&Itemid=292&ysclid=lc2lqy69zu494933446" to="/" />
        < Redirect from="/index.php?option=com_content&view=article&id=41&Itemid=292&ysclid=lc2lsvnwo4572338914" to="/" />
        < Redirect from="/component/content/?id=57&Itemid=208&ysclid=lc2lrfgu18573679996" to="/" />
        < Redirect from="/component/content/?id=57&Itemid=208&ysclid=lc2lt7dbjo442850064" to="/" />
        < Redirect from="/component/content/?id=41&Itemid=292&ysclid=lc2ltoj37v309821354" to="/" />
        < Redirect from="/component/content/?id=41&Itemid=292&ysclid=lc2luhulle26706328" to="/" />
        < Redirect from="/8-skidki-i-aktsii/30-besplatnyj-kupon-na-skidku-5-v-stomatologii-dantist-plyus-v-podolske.html?ysclid=lc2lv6yxv1528336685" to="/skidki-i-aktsii" />
        < Redirect from="/8-skidki-i-aktsii/30-besplatnyj-kupon-na-skidku-5-v-stomatologii-dantist-plyus-v-podolske.html?ysclid=lc2lvnujj0452163847" to="/skidki-i-aktsii" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
