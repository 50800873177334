import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './lechenie-zubov.module.scss';
import image from '../../../images/lechenie-zubov-1.jpg';

function LechenieZubov() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Лечение зубов в Подольске – быстро, недорого и без боли</h1>
						<p>Специалисты стоматологической клиники «Дантист Плюс» осуществляют лечение зубов в Подольске по самым низким ценам в городе. Мы быстро и эффективно устраняем любые проблемы в ротовой полости, что позволяет нашим пациентам забыть о болезненных и неприятных ощущениях. Опыт квалифицированных стоматологов, современное техническое и медикаментозное оснащение, желание помочь каждому пациенту – вот что является залогом успешного лечения в каждом конкретном случае.</p>
						<p className={styles.bold_text}>Консультация стоматолога в «Дантист Плюс» абсолютно бесплатна!</p>
					</div>
					<div className={cn(styles.wrapper_desc, styles.wrapper_desc_revers)}>
						<div className={cn(styles.wrapper_image, styles.wrapper_image_revers)}>
							<h2 className={styles.title_image}>Семь бед – один ответ</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Частое потребление кофе и чая, неправильное питание, недостаток витаминов и микроэлементов в организме, неочищенная питьевая вода, неверная гигиена полости рта – от всего этого разрушаются наши зубы, образовывается налет и зубной камень, который приводит к болезням десен.</p>	
							<p>Пускать на самотек данные процессы не следует, ведь можно полностью потерять зуб. Лучше своевременно обращаться для лечения зубов в клинику «Дантист Плюс» в Подольске, где быстро обнаружат проблему и помогут с ее решением.</p>
						</div>
					</div>
					<p>Стоматологи «Дантист Плюс» осуществляют лечение зубов:</p>
					<ul>
						<li>Кариеса на ранних и запущенных стадиях</li>
						<li>Лечение пародонтоза и периодонтита</li>
						<li>Устранение кисты зуба</li>
						<li>Лечение пульпита</li>
						<li>Устранение кровоточивости десен</li>
						<li>Лечение стоматита</li>
						<li>Лечение гранулемы</li>
					</ul>
					<img src={image}></img>
					<h2 className={styles.title}>Как происходит прием</h2>
					<p>Изначально вас записывают на прием к стоматологу-терапевту, который оценивает общее состояние полости рта, находит причину проблемы, на которую жалуется пациент. После этого принимается решение о лечении зуба или направлении вас к другому специалисту (врачу хирургу, пародонтологу, ортопеду, имплантологу и пр.). Перед началом лечения вас также предложат рентгенографию, чтобы исключить вероятность невидимых разрушений зуба, прикорневого кариеса, кисты. Это позволяет оказать помощь комплексно.</p>
					<p>«Дантист Плюс» – лечение европейского уровня по российским ценам</p>
					<p>Обратившись в стоматологическую клинику «Дантист Плюс» в Подольске, вы можете быть уверены, что тут:</p>
					<ul>
						<li>Внимательный и профессиональный подход к каждому пациенту. Для лечения зубов к нам обращаются беременные женщины, люди, склонные к аллергии на медицинские препараты, люди с различными хроническими заболеваниями. Для них мы подбираем индивидуальные медицинские препараты для анестезии, а также щадящие методы диагностики.</li>
						<li>Доступные цены. Вы сами можете убедиться, что у нас лучшие цены в городе, ознакомившись с нашим прайсом. К тому же, у нас всегда есть скидки.</li>
						<li>Современное оборудование, позволяющее предоставить услуги на высшем уровне. В своей работе мы используем ультразвуковое оборудование, которое позволяет выполнить работу максимально быстро и точно. Для диагностики и последующего выполнения работ врач стоматолог использует бинокуляры, которые обеспечивают буквально ювелирную работу с каждым зубом.</li>
						<li>Полное соблюдение гигиенических и медицинских норм. Мы используем только одноразовые или хорошо простерилизованные инструменты, материалы, подручные средства. Все процедуры максимально безопасны.</li>
						<li>Без боли. Мы используем анестезию для всех видов лечения зубов, что избавит вас от неприятных и болезненных ощущений</li>
					</ul>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default LechenieZubov;
