import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './price-kids.module.scss';

function PriceKids({ isKidsPage = false }) {
  return (
    <div className={cn(styles.prices, 'container')}>
      <h2 className={styles.title}>{isKidsPage ? 'Цены на детскую стоматологию' : 'Детская стоматология'}</h2>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>8.0</td>
            <td>Консультация</td>
            <td>бесплатно</td>
          </tr>
          <tr>
            <td>8.0.1</td>
            <td>Психологическая подготовка (15 мин)</td>
            <td>550,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.pink_subtitle}>Анестезия</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>8.1</td>
            <td>Анестезия (апликационная)</td>
            <td>305,00 р</td>
          </tr>
          <tr>
            <td>8.2</td>
            <td>Анестезия (инфильтрационная)</td>
            <td>550,00 р</td>
          </tr>
          <tr>
            <td>8.3</td>
            <td>Анестезия (проводниковая)</td>
            <td>600,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.blue_subtitle}>Лечение</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>9.1</td>
            <td>Лечение кариеса / Мед.обработка полости</td>
            <td>730,00 р</td>
          </tr>
          <tr>
            <td>9.2</td>
            <td>Препарирование / удаление старой пломбы</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>9.3</td>
            <td>Прокладка изолирующая</td>
            <td>490,00 р</td>
          </tr>
          <tr>
            <td>9.4</td>
            <td>Полировка, шлифовка пломбы</td>
            <td>490,00 р</td>
          </tr>
          <tr>
            <td>9.5</td>
            <td>Наложение девитализирующей повязки</td>
            <td>850,00 р</td>
          </tr>
          <tr>
            <td>9.6</td>
            <td>Мех., мед.обработка корневого канала (1 ед.) / ампутация, экстирпация</td>
            <td>850,00 р</td>
          </tr>
          <tr>
            <td>9.7</td>
            <td>Пломбировка к/к Эодент, Форфенан, Цинк оксид-эвгенольной пастой</td>
            <td>1 550,00 р</td>
          </tr>
          <tr>
            <td>9.8</td>
            <td>Временная полмбировка к/к Крезофен, Каласепт</td>
            <td>790,00 р</td>
          </tr>
          <tr>
            <td>9.9</td>
            <td>Постановка временной пломбы Парасепт, septopak</td>
            <td>490,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.green_subtitle}>Пломбировочный материал</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>10.1</td>
            <td>Пломба Fuji</td>
            <td>1 350,00 р</td>
          </tr>
          <tr>
            <td>10.2</td>
            <td>Пломба Vitremer</td>
            <td>2 150,00 р</td>
          </tr>
          <tr>
            <td>10.3</td>
            <td>Пломба  Ceram</td>
            <td>2 800,00 р</td>
          </tr>
          <tr>
            <td>10.4</td>
            <td>Использование матричной системы (востановление зуба пломбой)</td>
            <td>550,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.pink_subtitle}>Гигиена</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>11.1</td>
            <td>Профессиональная чистка зубов пастой (1 ед.) (Профессиональная гигиена полости рта и зубов)</td>
            <td>180,00 р</td>
          </tr>
          <tr>
            <td>11.2</td>
            <td>Профессиональная чистка зубов скалером (1 ед.) (Профессиональная гигиена полости рта и зубов)</td>
            <td>190,00 р</td>
          </tr>
          <tr>
            <td>11.3</td>
            <td>Чистка Air-flow (1 ед.) (Профессиональная гигиена полости рта и зубов)</td>
            <td>190,00 р</td>
          </tr>
          <tr>
            <td>11.4</td>
            <td>Герметизация Фиссур (1 ед.) (Запечатывание фиссуры зуба герметиком)</td>
            <td>2 200,00 р</td>
          </tr>
          <tr>
            <td>11.5</td>
            <td>Обработка зубов фторсодержащими средствами (1 ед.) (Глубокое фторирование эмали зуба)</td>
            <td>200,00 р</td>
          </tr>
          <tr>
            <td>11.6</td>
            <td>Обучение гигиене полости рта и зубов индивидуальное, подбор средств и предметов гигиены полости рта</td>
            <td>370,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.blue_subtitle}>Пародонталогия</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>12.1</td>
            <td>Наложение лечебной пародонтальной повязки </td>
            <td>600,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.green_subtitle}>Хирургическая стоматология</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>13.1</td>
            <td>Удаление молочного зуба</td>
            <td>1 150,00 р</td>
          </tr>
          <tr>
            <td>13.2</td>
            <td>Удаление молочного зуба (подвижного)</td>
            <td>800,00 р</td>
          </tr>
          <tr>
            <td>13.3</td>
            <td>Удаление молочного зуба (сложного)</td>
            <td>1 500,00 р</td>
          </tr>
        </tbody>
      </table>
      <h3 className={styles.pink_subtitle}>Ортодонтия</h3>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>14.1</td>
            <td>Система ICON</td>
            <td>3 000,00 р</td>
          </tr>
          <tr>
            <td>14.2</td>
            <td>Наружная прозрачная сапфировая брекет-система (2 сложности)</td>
            <td>72 600,00 р</td>
          </tr>
          <tr>
            <td>14.3</td>
            <td>Фиксация несъемного ретейнера</td>
            <td>7 700,00 р</td>
          </tr>
          <tr>
            <td>14.4</td>
            <td>Удаление налета, гигиеническая чистка (1 зуб)</td>
            <td>120,00 р</td>
          </tr>
          <tr>
            <td>14.5</td>
            <td>Трейнер преортодонтический</td>
            <td>9 900,00 р</td>
          </tr>
          <tr>
            <td>14.6</td>
            <td>Технология Invisaling (ортодонтическое лечение с помощью прозрачных съемных капп)</td>
            <td>286 000,00 р</td>
          </tr>
          <tr>
            <td>14.7</td>
            <td>Спортивная каппа</td>
            <td>9 680,00 р</td>
          </tr>
          <tr>
            <td>14.8</td>
            <td>Аппарат RMA для форсированного расширения верхней челюсти</td>
            <td>30 250,00 р</td>
          </tr>
          <tr>
            <td>14.9</td>
            <td>Аппарат Haas для форсированного расширения верхней челюсти</td>
            <td>30 250,00 р</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Аппарат Forsus для выдвижения нижней челюсти</td>
            <td>36 300,00 р</td>
          </tr>
          <tr>
            <td>15.1</td>
            <td>Анализ ТРГ (прием, осмотр, консультация врача-ортодонта вторичный)</td>
            <td>1 200,00 р</td>
          </tr>
          <tr>
            <td>15.2</td>
            <td>Лечение с применением ортодонтических мини-имплантов (1 шт)</td>
            <td>10 000,00 р</td>
          </tr>
          <tr>
            <td>15.3</td>
            <td>Аппарат Гербста для выдвижения нижней челюсти</td>
            <td>36 300,00 р</td>
          </tr>
          <tr>
            <td>15.4</td>
            <td>Детская ортодонтическая брекет-система</td>
            <td>30 250,00 р</td>
          </tr>
          <tr>
            <td>15.5</td>
            <td>Диагностика (фото интраоральные, анкета, снятие слепков, изготовление моделей, планирование лечения)</td>
            <td>6 050,00 р</td>
          </tr>
          <tr>
            <td>15.6</td>
            <td>Ежемесячная оплата (ортодонтическая коррекция)</td>
            <td>7 700,00 р</td>
          </tr>
          <tr>
            <td>15.7</td>
            <td>Ежемесячная оплата (ортодонтическая коррекция)</td>
            <td>7 150,00 р</td>
          </tr>
          <tr>
            <td>15.8</td>
            <td>Местосохраняющее кольцо (изготовление кольца ортодонтического)</td>
            <td>12 100,00 р</td>
          </tr>
          <tr>
            <td>15.9</td>
            <td>Лечение с применением двучелюстного аппарата Твин-Блок</td>
            <td>27 500,00 р</td>
          </tr>
          <tr>
            <td>16</td>
            <td>LM-активатор</td>
            <td>11 000,00 р</td>
          </tr>
          <tr>
            <td>16.1</td>
            <td>Лечение с применением ортодонтической пластины с винтом Бертони</td>
            <td>13 300,00 р</td>
          </tr>
          <tr>
            <td>16.2</td>
            <td>Наружная металлическая безлигатурная брекет-система</td>
            <td>45 000,00 р</td>
          </tr>
          <tr>
            <td>16.3</td>
            <td>Ретенционная прозрачная каппа</td>
            <td>4 850,00 р</td>
          </tr>
          <tr>
            <td>16.4</td>
            <td>Ортодонтическое лечение на брекет-системе 1 степень</td>
            <td>140 000,00 р</td>
          </tr>
          <tr>
            <td>16.5</td>
            <td>Ортодонтическое лечение на брекет-системе 2 степень</td>
            <td>160 000,00 р</td>
          </tr>
          <tr>
            <td>16.6</td>
            <td>Наружная прозрачная сапфировая брекет-система (1 сложности)</td>
            <td>60 500,00 р</td>
          </tr>
          <tr>
            <td>16.7</td>
            <td>Наружная металлическая лигатурная брекет-система</td>
            <td>45 000,00 р</td>
          </tr>
          <tr>
            <td>16.8</td>
            <td>Лечение с применением ортодонтической пластины I сложность (1 челюсть)</td>
            <td>12 650,00 р</td>
          </tr>
          <tr>
            <td>16.9</td>
            <td>Наблюдение за ходом лечения, коррекция на съемных аппаратах</td>
            <td>650,00 р</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Лингвальная брекет-система Win (2 челюсти)</td>
            <td>198 000,00 р</td>
          </tr>
          <tr>
            <td>17.1</td>
            <td>Снятие альгинатного слепка (1 челюсть)</td>
            <td>600,00 р</td>
          </tr>
          <tr>
            <td>17.2</td>
            <td>Прозрачная керамическая самолигурирующая брекет-система In Ovation</td>
            <td>65 000,00 р</td>
          </tr>
          <tr>
            <td>17.3</td>
            <td>Снятие брекет-системы</td>
            <td>7 150,00 р</td>
          </tr>
          <tr>
            <td>17.4</td>
            <td>Каппа от бруксизма</td>
            <td>5 050,00 р</td>
          </tr>
          <tr>
            <td>17.5</td>
            <td>Каппа депрограмматор</td>
            <td>17 050,00 р</td>
          </tr>
          <tr>
            <td>17.6</td>
            <td>Лечение с применением ортодонтической пластины II сложности (1 челюсть)</td>
            <td>13 900,00 р</td>
          </tr>
          <tr>
            <td>17.7</td>
            <td>Аппарат Macnamara</td>
            <td>30 250,00 р</td>
          </tr>
          <tr>
            <td>17.8</td>
            <td>Лицевая маска</td>
            <td>7 700,00 р</td>
          </tr>
          <tr>
            <td>17.9</td>
            <td>Починка съемного аппарата (ремонт ортодонтического аппарата)</td>
            <td>1 200,00 р</td>
          </tr>
          <tr>
            <td>18</td>
            <td>Сеппарация (ортодонтическая коррекция)</td>
            <td>1 100,00 р</td>
          </tr>
          <tr>
            <td>18.2</td>
            <td>Ортодонтическое лечение на брекет-системе 3 степень</td>
            <td>180 000,00 р</td>
          </tr>
          <tr>
            <td>18.3</td>
            <td>Ортодонтическое лечение на брекет-системе 4 степень</td>
            <td>200 000,00 р</td>
          </tr>
          <tr>
            <td>18.4</td>
            <td>Ортодонтическое лечение на брекет-системе 5 степень</td>
            <td>220 000,00 р</td>
          </tr>
          <tr>
            <td>18.5</td>
            <td>Ежемесячная оплата (ортодонтическая коррекция)</td>
            <td>10 000,00 р</td>
          </tr>
          <tr>
            <td>18.6</td>
            <td>Лечение с применением съемной ортодонтической пластины 1 винт</td>
            <td>18 000,00 р</td>
          </tr>
          <tr>
            <td>18.7</td>
            <td>Лечение с применением съемной ортодонтической пластины 2 винта</td>
            <td>19 000,00 р</td>
          </tr>
          <tr>
            <td>18.8</td>
            <td>Лечение с применением съемной ортодонтической пластины 3 винта</td>
            <td>20 000,00 р</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

PriceKids.propTypes = {
  isKidsPage: PropTypes.bool,
};

export default PriceKids;
