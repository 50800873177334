import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './protez.module.scss';

function Protez() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Протезирование – восстановление утраченных зубов</h1>
						<p>Стоматология «Дантист Плюс» предлагает услуги протезирования зубов в Подольске, что позволит быстро и безболезненно вернуть функциональность челюсти. Вы будете самостоятельно справляться с любой пищей, вновь полноценно почувствуете вкус еды, забудете о дискомфорте, связанном с отсутствием одного зуба или части челюсти, будете вновь улыбаться, демонстрируя здоровые и красивые зубы.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Дантист Плюс – протезирование зубов без боли и хлопот</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Специалисты нашей клиники выполняют протезирование зубов любой сложности, предварительно оценив состояние полости рта больного, места, где когда-то был зуб, а теперь требуется его замена.</p>
							<p>Пациенту предлагаются оптимальные варианты протезирования, из которых он может выбрать то, которое подходит не только по конечному результату, но и по цене.</p>
						</div>
					</div>
					<p>При этом вы можете быть уверены, что у нас:</p>
					<ul>
						<li>Лучшие цены в Подольске</li>
						<li>Эффективная анестезия</li>
						<li>Опытные специалисты</li>
						<li>Современные материалы и оборудование для протезирования зубов</li>
					</ul>
					<p className={styles.bold_text}>Обратившись в стоматологическую клинику «Дантист Плюс», вы можете быть уверены, что попадете в руки опытному вручу протезисту, который вернет вашей улыбке здоровый и привлекательный вид.</p>
					<h2 className={styles.title}>Протезирование зубов: выбираем варианты</h2>
					<p>Вид протеза рекомендуется стоматологом, который предварительно осматривает ротовую полость, при необходимости делает дополнительно снимки или компьютерное обследование челюсти. В результате принимается решение о том или ином виде протезирования:</p>
					<p className={styles.bold_text}>По характеру фиксации</p>
					<ul>
						<li>Несъемное. Полное восстановление зуба или его части. Для протезирования используются коронки, мостовые протезы, цементирующий материал.</li>
						<li>Съемное протезирование. Позволяет восстановить зубной ряд. Показано при потере сразу нескольких зубов по причине травмы челюсти, возрастных изменений, удалению зубов по состоянию здоровья и пр. Различают полностью съемные протезы, условно-съемныеи частично съемные.</li>
					</ul>
					<p className={styles.bold_text}>По виду фиксирующего протеза</p>
					<ul>
						<li>Одиночные коронки. Позволяют частично сохранить разрушающийся зуб, восстанавливают эстетический вид, защищают корень.</li>
						<li>Люминиры. Тонкие виниры для сохранения разрушающегося или выщербленного зуба.</li>
						<li>Виниры. Реставрация зубов для восстановления эстетики.</li>
						<li>Литые штифтовые культевые вкладки. Протез крепится на штифт, введенный на место удаленного зуба.</li>
						<li>Мостовидные протезы. Позволяют восстановить жевательные функции сразу нескольких находящихся рядом зубов.</li>
						<li>Вкладки. Напоминают пломбу, однако позволяют заменить ¾ зуба, сохранив здоровую стенку. При этом в корень зуба с удаленным нервом вставляется штифт, на который и крепится вкладка.</li>
					</ul>
					<p className={styles.bold_text}>По материалу зубной коронки</p>
					<ul>
						<li>Композитные</li>
						<li>Металлокомпозитные</li>
						<li>Пластмассовые</li>
						<li>Цельнокерамические</li>
						<li>Из оксида алюминия и диоксида циркония</li>
						<li>Штампованные металлические</li>
						<li>Металлокерамические</li>
						<li>Из драгоценных металлов (золота, серебра)</li>
					</ul>
					<p className={styles.bold_text}>Периода, на который устанавливается коронка</p>
					<ul>
						<li>Временные. На срок от нескольких недель до года, чтобы восстановить жевательные процессы и эстетику</li>
						<li>Длительные. На продолжительный срок. С целью обеспечения максимальной гигиены полости рта выбирается металлокомпозит или металлопластмасса.</li>
					</ul>
					<p className={styles.bold_text}>По виду съемного протезирования</p>
					<ul>
						<li>По фиксации: кламмерные, замковые, телескопические и с помощью пелотов.</li>
						<li>По форме и материалам: нейлоновые, малые седловидные, пластиночные акриловые и с металлическим базисом, бюгельные и дуговые.</li>
					</ul>
					<p>Изготовление протеза осуществляется индивидуально на основе слепка зуба или челюсти больного, что обеспечивает идеальное прилегание и фиксацию.  Цена протезирования зубов зависит от выбранного вида протеза, количества используемого материала, сложности работы.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Protez;
