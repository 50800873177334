import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './koronki-iz-oksida.module.scss';

function Koronki() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Коронки из оксида циркония - изготовление и цена в Подольске</h1>
						<p>Коронки из оксида циркония создаются из сверхпрочного материала, благодаря современным компьютерным технологиям.</p>
						<p>Почти 20 лет оксид циркония используют в стоматологии. Стоматологи пациенты отдают свое предпочтение данному материалу. Коронки из циркония устанавливают и на передние зубы и на жевательные.</p>
						<p>Перед проведением различных видов стоматологических процедур, таких как протезированием и имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Преимущества коронок из оксида циркония:</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Коронка из оксида циркония обладает светопроницаемостью сходной со светопроницаемостью натуральных зубов, это делает их неотличимыми. По точности изготовления и прилегания конструкций цирконий не имеет конкурентов.</p>
							<p>Срок службы коронок на оксиде циркония неограничен.</p>
						</div>
					</div>
					<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление зубных коронок из циркония в кротчайшие сроки. Наши коронки - это долговечный способ забыть о проблемах с зубами!</p>
					<p>Вам понравится стоимость зубных коронок, ведь мы исходим из конкретной ситуации. Вы останетесь довольны ценой и качеством.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Koronki;
