import React from 'react';
import Header from '../header/header';
import Price from '../price/price';
import PriceKids from '../price-kids/price-kids';
import YandexMap from '../yandex-map/yandex-map';
import OrderBottom from '../order-bottom/order-bottom';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import styles from './price-page.module.scss';

function PricePage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className="visually-hidden">Цены в стоматологии «Дантист Плюс» в Подольске</h1>
        <Price />
        <PriceKids />
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default PricePage;
