import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, A11y } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import galleryFirst from '../../images/gallery-1.jpg';
import gallerySecond from '../../images/gallery-2.jpg';
import galleryThird from '../../images/gallery-3.jpg';
import galleryFourth from '../../images/gallery-4.jpg';
import galleryFifth from '../../images/gallery-5.jpg';
import gallerySixth from '../../images/gallery-6.jpg';
import gallerySeventh from '../../images/gallery-7.jpg';
import galleryEighth from '../../images/gallery-8.jpg';
import galleryNinth from '../../images/gallery-9.jpg';
import swiperFirst from '../../images/gallery-11.jpg';
import swiperSecond from '../../images/gallery-12.jpg';
import swiperThird from '../../images/gallery-13.jpg';
import swiperFourth from '../../images/gallery-14.jpg';
import swiperFifth from '../../images/gallery-15.jpg';
import swiperSixth from '../../images/gallery-16.jpg';
import swiperSeventh from '../../images/gallery-17.jpg';
import swiperEighth from '../../images/gallery-18.jpg';
import swiperNinth from '../../images/gallery-19.jpg';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './gallery.module.scss';

SwiperCore.use([Autoplay, A11y, Pagination]);

function Gallery() {

  const isMobile = useMediaQuery({
    query: '(max-width: 743px)',
  });
  return (
    <div className={styles.wrapper}>
      <div className={styles.gallery}>
        <h2 className={styles.title}>Галерея</h2>
        {isMobile &&
        <Swiper
        // eslint-disable-next-line no-nested-ternary
          slidesPerView={1}
          a11y
          loop
          pagination
        >
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperFirst} alt='Детский кабинет' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperThird} alt='Взрослый кабинет' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperSecond} alt='Детский кабинет' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperFourth} alt='Ресепшен' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperFifth} alt='Коридор' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperSixth} alt='Детский столик' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperSeventh} alt='Кабинет с микроскопом' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperEighth} alt='Специльная установка' width="2000" height="1333" />
          </SwiperSlide>
          <SwiperSlide>
            <img className={styles.image_mobile} src={swiperNinth} alt='Стоматологическое кресло' width="370" height="483" />
          </SwiperSlide>
        </Swiper>}
        <div className={styles.photos}>
          <div className={styles.item}>
            <img className={styles.image} src={galleryFirst} alt='Детский кабинет' width="370" height="483" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={galleryThird} alt='Взрослый кабинет' width="370" height="483" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={gallerySecond} alt='Детский кабинет' width="370" height="483" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={galleryFourth} alt='Ресепшен фото' width="370" height="257" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={galleryFifth} alt='Коридор' width="370" height="257" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={gallerySixth} alt='Детский столик' width="370" height="257" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={gallerySeventh} alt='Кабинет с микроскопом' width="370" height="483" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={galleryEighth} alt='Специльная установка' width="370" height="483" />
          </div>
          <div className={styles.item}>
            <img className={styles.image} src={galleryNinth} alt='Стоматологическое кресло' width="370" height="483" />
          </div>
        </div>
      </div >
    </div>
  );
}

export default Gallery;
