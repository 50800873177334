import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './vkladka.module.scss';

function Vkladka() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Керамическая вкладка - изготовление и цена в Подольске</h1>
						<p>Керамическая вкладка - это очень прочный и высокоэстетичный цельнокерамический микропротез.</p>
						<p>Показаниями к установке керамической вкладки являются:</p>
						<ul>
							<li>большие пломбы на зубах</li>
							<li>сколы</li>
							<li>отломы зубов</li>
							<li>цвет, форма или положение зубов</li>
						</ul>
						<p>Перед проведением различных видов стоматологических процедур, таких как протезированием и имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Преимущества керамических вкладок:</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p><b>Прочность. </b>Керамические вкладки намного прочнее, композитных пломб, это приимущество при повышенной стираемости зубов.</p>
							<p><b>Эстетичность. </b>Свет, проходящий через полупрозрачную керамику, идентичен свету эмали и дентина естественного зуба. Керамические вкладки устойчивы к внешним воздействиям и не изменяют свой цвет с течением времени.</p>
							<p>Если коронковая часть зуба разрушена более, чем на 1/3 то это прямое показание к изготовлению вкладки.
Еще одним неоспоримым плюсом керамических вкладок является безукоризненный эстетический вид зубов.</p>
						</div>
					</div>
					<p>Керамические вкладки помогают с идеальной точностью восстановить контактный пункт между зубами, в отсутствии одной из стенок зуба.</p>
					<p>Керамические вкладки точно повторяют анатомическую форму зубов.</p>
					<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление керамических вкладок на зубы в кротчайшие сроки. Это отличный способ забыть о проблемах с зубами!</p>
					<p>Вам понравится цена на зубные керамические вкладки, ведь мы исходим из конкретной ситуации. Вы останетесь довольны стоимостью и качеством.</p>
					<p>Вкладка керамическая - это идеальная и современная альтернатива композитным пломбам.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Vkladka;
