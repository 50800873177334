import React from 'react';
import Header from '../header/header';
import Services from '../services/services';
import YandexMap from '../yandex-map/yandex-map';
import OrderBottom from '../order-bottom/order-bottom';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import styles from './adult-page.module.scss';
import { terapiya, ortoped, khirurgiya, paradontologiya, estet, child } from '../../const';

function AdultPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<h1 className='visually-hidden'>Cтоматология "Дантист Плюс в Подольске</h1>
        <div id="terapiya" >
          <Services title="Терапевтическая стоматология" content={terapiya} />
        </div>
        <div id="ortoped">
          <Services title="Ортопедическая стоматология" content={ortoped} />
        </div>
				<div id="khirurgiya">
          <Services title="Хирургическая стоматология" content={khirurgiya} />
        </div>
        <div id="paradontologiya">
          <Services title="Пародонтология" content={paradontologiya} />
        </div>
        <div id="estet">
          <Services  title="Эстетическая стоматология" content={estet} />
        </div>
        <div>
          <Services  title="Детская стоматология" content={child} />
        </div>
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default AdultPage;