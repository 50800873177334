import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import Header from '../header/header';
import PriceKids from '../price-kids/price-kids';
import OrderBottom from '../order-bottom/order-bottom';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import kadzaeva from '../../images/kadzaeva.jpg';
import styles from './kids-page.module.scss';

function KidsPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className='visually-hidden'>Детская стоматология «Дантист Плюс» в Подольске</h1>
        <div className={styles.image}>
          <div className={styles.main_text}>
            <h2 className={styles.title}>Детская стоматология</h2>
            <p className={styles.text}>Маленькие пациенты требуют особого внимания и бережного отношения. С самого первого посещения кабинета стоматолога ребенку нужно сформировать правильное отношение к лечению зубов, иначе в дальнейшем психологическая травма останется на всю жизнь. Для этого родителям необходимо серьезно подойти к выбору как стоматологии, так и лечащего врача.</p>
            <p className={styles.text}>Мы с особым вниманием подошли к этому вопросу и создали для ваших детей комфортные условия и подобрали самые щадящие методы лечения! Наше современное оборудование и чуткий персонал сделают всё, чтобы малыши не боялись посещать кабинет стоматолога в будущем!</p>
          </div>
        </div>
        <PriceKids isKidsPage />
        <div className={cn('container', styles.doc)}>
          <div className={styles.container}>
            <div className={styles.description}>
              <h3 className={styles.name}>Кадзаева Александра Сергеевна</h3>
              <p className={styles.speciality}>Главный врач, стоматолог-терапевт, стоматолог детский</p>
              <p className={styles.experience}>Стаж работы 7 лет</p>
              <ul className={styles.diplomas}>
                <li className={styles.diploma}>2013 «Российский Национальный Исследовательский Медицинский Университет им.Н.И.Пирогова.» Стоматологический факультет</li>
              </ul>
              <p className={styles.text}>Основные направления: Реставрации, эндодонтическое лечение, врач - микроскопист.</p>
              <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
            </div>
            <img className={styles.photo} src={kadzaeva} alt='Кадзаева Александра Сергеевна' width="1200" height="1800" />
          </div>
        </div>
      </main>
      <OrderBottom />
      <YandexMap />
      <ScrollButton />
      <Footer />
    </div>
  );
}

export default KidsPage;
