import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import sazonova from '../../images/sazonova.jpg';
import styles from './order-bottom.module.scss';

function OrderBottom() {
  return (
    <div className={cn('container', styles.doc)}>
      <div className={styles.container}>
        <div className={styles.description}>
          <h3 className={styles.title}>Записаться на прием к стоматологу</h3>
          <div className={styles.phones}>
            <a className={styles.phone} href="tel:+74967524444">8 (4967) 52-44-44</a>
            <a className={styles.phone} href="tel:+79651978020">8 (965) 197-80-20</a>
          </div>
          <p className={styles.text}>Осмотр, консультация и составление плана лечения БЕСПЛАТНО!</p>
          <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
        </div>
        <img className={styles.photo} src={sazonova} alt='Сазонова Светлана Сергеевна' width="1200" height="1800" />
      </div>
    </div>
  );
}

export default OrderBottom;
