import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './kista.module.scss';

function Kista() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Лечение кисты зуба - цена</h1>
						<p>Киста зуба – это образование в полости рта, заполненное жидкостью. Располагается она вокруг верхней части корня зуба.</p>
						<p>Перед проведением удаления зуба или имплантации зубов, рекомендуется процедура чистки зубов.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Причины появления кисты</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Киста может долгое время не доставлять никаких неудобств, но она несет угрозу для здоровья и сохранности зуба. Полость кисты со временем понемногу увеличивается, тем самым уничтожая ткани внутри самого зуба.</p>
							<p>Причины появления кисты разнообразны. Например, попадание инфекции в зуб из-за травмы или инфекционное заболевание.</p>
							<p>Киста – это третья стадию воспаления зуба. Первой стадией является гранулема - тонкая капсула, окруженная соединительной тканью. Если в капсуле гранулемы появляется воспалительная жидкость, то возникает кистгранулема. Заполнившись полностью лимфой, мешочек становится кистой зуба.</p>
						</div>
					</div>
					<p>Обнаружить кисту можно благодаря рентгеновскому снимку. Лечиться киста двумя способами – безоперационным и хирургическим.</p>
					<p>Чтобы избежать появления кисты, необходимо два раза в год посещать кабинет стоматолога, а так же чистить зубы и вовремя их лечить.</p>
					<p>Вам понравится стоимость удаления кисты зуба, ведь мы исходим из конкретной ситуации. Вы останетесь довольны ценой и качеством.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением и по возможности сохранить зуб. Чтобы избежать появления кисты, необходимо два раза в год посещать кабинет стоматолога, а так же чистить зубы и вовремя их лечить.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Kista;
