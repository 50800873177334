import React, { useState, useRef } from 'react';
import cn from 'classnames';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import Header from '../header/header';
import OrderBottom from '../order-bottom/order-bottom';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import styles from './order-page.module.scss';

function OrderPage() {
  const [count, setCount] = useState(5);

  const refCaptcha = useRef();

  const form = useRef();

  const [toSend, setToSend] = useState({
    name: '',
    phone: '',
    message: '',
    visit: 'yes',
    date: '',
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = refCaptcha.current.getValue();

    const params = {
      ...toSend,
      'g-recaptcha-response': token,
    };

    emailjs.send('service_pms02up', 'template_8zxzipi', params, 'P5MeWMgi8ntcxZNm2', 'g-recaptcha-response')
      .then((result) => {
        e.target.reset();
        window.grecaptcha.reset();
        setSuccess(true);
        setError(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }, (error) => {
        setError(true);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className='visually-hidden'>Записаться на прием в стоматологию &quot;Дантист Плюс&quot; в Подольске</h1>
        <div className={styles.order_bg}>
          <div className={cn(styles.orders, 'container')}>
            <h2 className={styles.title}>Online - запись на прием</h2>
            <p className={styles.text}>Для записи на прием к стоматологу Вам необходимо заполнить форму ниже.</p>
            <p className={styles.text}>После отправки сообщения Вам перезвонят с уведомлением об успешной записи на прием.</p>
            <div className={styles.send}>
              <form ref={form} onSubmit={handleSubmit} className={styles.form} >
                <div className={styles.labels}>
                  <label className={styles.label} htmlFor="name">Ваше имя *</label>
                  <input onChange={handleChange} type="text" id="name" name="name" aria-label="Имя" className={styles.input} required></input>
                </div>
                <div className={styles.labels}>
                  <label className={styles.label} htmlFor="phone">Телефон для связи *</label>
                  <input onChange={handleChange} type="tel" id="phone" pattern="([\+]*[7-8]{1}\s?[\(]*9[0-9]{2}[\)]*\s?\d{3}[-]*\d{2}[-]*\d{2})" name="phone" aria-label="Телефон" placeholder="+7 (___) ___-__-__" className={styles.input} required></input>
                </div>
                <div className={styles.labels}>
                  <label className={styles.label} htmlFor="about">Какие жалобы? (не обязательно)</label>
                  <textarea onChange={handleChange} id="about" className={styles.textarea} name="message" aria-label="Отзыв" rows="5"></textarea>
                </div>
                <div className={styles.labels}>
                  <p className={styles.label}>Посещали ли Вы &quot;Дантист плюс&quot; ранее?</p>
                  <div>
                    <div className={styles.label_input}>
                      <label htmlFor="yes">Да</label>
                      <input onChange={handleChange} id="yes" type="radio" name="visit" aria-label="Да" value="Да" className={cn(styles.input, styles.input_radio)}></input>
                    </div>
                    <div className={styles.label_input}>
                      <label htmlFor="no">Нет</label>
                      <input onChange={handleChange} id="no" type="radio" name="visit" aria-label="Нет" value="Нет" className={cn(styles.input, styles.input_radio)}></input>
                    </div>
                  </div>
                </div>
                <div className={styles.labels}>
                  <label className={styles.label} htmlFor="date">Желаемая дата и время приёма</label>
                  <input onChange={handleChange} type="text" id="date" name="date" aria-label="Дата записи" className={cn(styles.input, styles.input_date)}></input>
                </div>
                <p className={error ? styles.error : cn(styles.error, styles.error_none)}>* Заполните все поля</p>
                <div className={styles.captcha}>
                  <ReCAPTCHA ref={refCaptcha} sitekey="6LcrMJcjAAAAABTrEZjoVeuLABlfpFaj-EWknSYv" />
                </div>
                <div className={success ? styles.success : cn(styles.success, styles.success_none)}>Форма успешно отправлена</div>
                <button className={styles.button_form} type="submit" aria-label="Отправить">Отправить</button>
              </form>
            </div>
            <p className={styles.text}>Кроме того, в стоматологию &quot;Дантист плюс&quot; можно записаться по телефонам:</p>
            <a className={styles.link} href="tel:+74967524444">+7 (4967) 52-44-44</a>
            <a className={styles.link} href="tel:+79651978020">+7 (965) 197-80-20</a>
          </div>
          <OrderBottom />
        </div>
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default OrderPage;
