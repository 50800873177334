
import React from 'react';
import Header from '../header/header';
import Department from '../department/department';
import Services from '../services/services';
import Promo from '../promo/promo';
import YandexMap from '../yandex-map/yandex-map';
import Licenses from '../licenses/licenses';
import Gallery from '../gallery/gallery';
import OrderBottom from '../order-bottom/order-bottom';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import styles from './main-page.module.scss';
import { services } from '../../const';

function MainPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className='visually-hidden'>Стоматология «Дантист Плюс» в Подольске</h1>
        <Department />
        <Services title="Услуги стоматологии" content={services} />
        <Promo isMainPage />
        <Licenses />
        <Gallery />
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default MainPage;
