import ponizova from './images/nina-viktorovna2.jpg';
import levitanskii from './images/levitanskiy.jpg';
import kadzaeva from './images/kadzaeva.jpg';
import lukyanchikov from './images/lukyanchikov.jpg';
import sivirchukov from './images/sivirchukov.jpg';
import matchanova from './images/matchanova.jpg';
import budei from './images/budei.jpg';

const doctors = [
  {
    id: 0,
    name: 'Понизова Нина Викторовна',
    speciality: 'Врач стоматолог-терапевт, стоматолог детский',
    experience: 'Стаж работы 31 год',
    diplomas: ['1984 - 1989 "Московский медицинский стоматологический институт им.Семашко"'],
    text: 'Основные направления: терапия в полном объёме (взрослая и детская), реставрации, эндодонтическое лечение.',
    image: ponizova,
  },
  {
    id: 1,
    name: 'Черхавская (Кадзаева) Александра Сергеевна',
    speciality: 'Главный врач, стоматолог-терапевт, стоматолог детский',
    experience: 'Стаж работы 7 лет',
    diplomas: ['2013 "Российский Национальный Исследовательский Медицинский Университет им.Н.И.Пирогова." Стоматологический факультет'],
    text: 'Основные направления: терапия в полном объёме (взрослая и детская), реставрации, эндодонтическое лечение, врач – микроскопист.',
    image: kadzaeva,
  },
  {
    id: 2,
    name: 'Левитанский Максим Евгеньевич',
    speciality: 'Врач стоматолог-хирург, имплантолог',
    experience: 'Стаж работы 17 лет',
    diplomas: ['2001 - 2006 – «Тверская государственная медицинская академия Федерального агентства по здравоохранению и социальному развитию», специализация «Стоматология»', '006 - 2008 - Стажировка в Израиле', '2008 - по настоящее время практикующий хирург в собственной клинике в Израиле'],
    text: 'Основные направления: Хирургия в полном объеме, костная пластика, имплантация',
    image: levitanskii,
  },
  {
    id: 3,
    name: 'Лукьянчиков Игорь Алексеевич',
    speciality: 'Врач стоматолог-хирург, пародонтолог, стоматолог-ортопед',
    experience: 'Стаж работы 8 лет',
    diplomas: ['2007-2012 "Курский Государственный Медицинский Университет"'],
    text: 'Основные направления: Хирургия в полном объеме, удаления любой сложности, пародонтология. Все виды съемного и несъемного протезирования. Протезирование на имплантатах.',
    image: lukyanchikov,
  },
  {
    id: 4,
    name: 'Сивирчуков Виталий Николаевич',
    speciality: 'Врач стоматолог, терапевт',
    experience: 'Стаж работы 16 лет',
    diplomas: ['2007 "Волгоградский медицинский колледж"', '2007 ординатура "Государственное образовательное учреждение здравоохранения "Урюпинское медицинское училище №1". " Факультет Стоматологический. Специализация Зубной врач.', '2015 "Московский Государственный Медико-Стоматологический Университет" (МГМСУ), стоматологический факультет', '2016 интернатура "стоматология общей практики" МГМСУ'],
    text: 'Основные направления: терапия в полном объёме, реставрации, эндодонтическое лечение.',
    image: sivirchukov,
  },
  {
    id: 5,
    name: 'Матчанова Оксана Батыровна',
    speciality: 'Врач стоматолог-терапевт, пародонтолог',
    experience: 'Стаж работы 28 лет',
    diplomas: ['1995 "Ташкентский медицинский институт". Специализация стоматология, базовое образование', '1996 "Ташкентский медицинский институт". Специализация стоматология терапевтическая, интернатура'],
    text: 'Основные направления: терапия в полном объеме, реставрации, эндодонтическое лечение.',
    image: matchanova,
  },
  {
    id: 6,
    name: 'Будей Игорь Васильевич',
    speciality: 'Врач стоматолог-ортопед',
    experience: 'Стаж работы 10 лет',
    diplomas: ['2013 "Государственный университет медицины и фармакологии имени Николая Тестемицану". Специализация стоматология, базовое образование', '2014 "Первый московский государственный медицинский университет им. И.М. Сеченова". Специализация стоматология общей практики, интернатура', '2015 Циклы переподготовки'],
    text: 'Основные направления: функциональная и эстетическая реабилитация зубного ряда. Ортопедия в полном объеме: протезирование съемными и несъемными конструкциями (безметалловая керамика, металлокерамика), протезирование на имплантах.',
    image: budei,
  },
];

const AppRoute = {
  MAIN: '/',
  ABOUT: '/about',
  ADULT: '/adult',
  KIDS: '/detskaya-stomatologiya',
  PRICE: '/prajsy',
  VRACHI: '/spetsialisty',
  PROMO: '/skidki-i-aktsii',
  REVIEWS: '/otzyvy',
  ORDER: '/zapisatsya',
  CONTACTS: '/kontakty',
  LECHENIE_MICRO: '/lechenie-pod-mikroskopom',
  LECHENIE_ZUBOV: '/lechenie-zubov',
  PROTEZ_ZUBOV: '/protezirovanie-zubov',
  MICROPROTEZ: '/mikroprotezirovanie-zubov',
  MOST_PRPTEZ: '/mostovidnye-zubnie-protezy',
  NEJLON_PROTEZ: '/nejlonovye-zubnye-protezy',
  BYUGEL_PROTEZ: '/byugelnye-zubnye-protezy',
  KORONKI: '/koronki-iz-oksida-tsirkoniya',
  LYUMINIRY: '/lyuminiry',
  VKLADKA: '/vkladka-keramicheskaya-izgotovlenie',
  KULT_VKLADKA: '/kultevaya-vkladka',
  CHISTKA_ZUBOV: '/chistka-zubov',
  OTBEL_ZUBOV: '/otbelivanie-zubov',
  ULTRA_CHISTKA: '/ultrazvukovaya-chistka',
  AIR_FLOW: '/air-flow',
  KARIES: '/karies',
  PARODONTOZ: '/lechenie-parodontoza',
  PARODONTIT: '/parodontit',
  LECHEMIE_DESEN: '/lechenie-desen',
  STOMATIT: '/stomatit-lechenie',
  BREKETY: '/brekety-zubov-tsena-ustanovka',
  KISTA: '/kista-zuba',
  IMPLANTACIYA: '/implantaciya',
  UDALENIE: '/udalenie',
};

const startDate = {
  'full_year': '2006',
  'month': '03',
  'day': '20',
  'hours': '00',
  'minutes': '00',
  'seconds': '00',
};

const yearDecl = ['год', 'года', 'лет'];
const monthDecl = ['месяц', 'месяца', 'месяцев'];
const dayDecl = ['день', 'дня', 'дней'];
const hourDecl = ['час', 'часа', 'часов'];
const minuteDecl = ['минута', 'минуты', 'минут'];
const seconsDecl = ['секунда', 'секунды', 'секунд'];

const services = [
  {
    id: 0,
    tabName: 'Терапевтическая стоматология',
    title: 'Терапевтическая стоматология',
    text: 'Терапевтическая стоматология, сфокусированна на профилактике, диагностике и лечении заболеваний зубов, десен и слизистой оболочки полости рта.',
    link: '/adult#terapiya',
    image: require('./images/terapiya.jpg'),
    isMainService: true,
  },
  {
    id: 1,
    tabName: 'Ортопедическая стоматология',
    title: 'Ортопедическая стоматология',
    text: 'Ортопедическая стоматология предусматривает профилактические меры и лечение приобретенных патологий, аномалий, деформаций и повреждений всех органов жевательно-речевого аппарата.',
    link: '/adult#ortopediya',
    image: require('./images/ortopediya.jpg'),
    isMainService: true,
  },
  {
    id: 2,
    tabName: 'Хирургическая стоматология',
    title: 'Хирургическая стоматология',
    text: 'Хирургическая стоматология практикует такие методы лечения как: операции по удалению зубов, подсадка костной ткани, подготовка и имплантация зубов, а также разнообразные эстетические операции в пародонтологии.',
    link: '/adult#khirurgiya',
    image: require('./images/khirurgiya.jpg'),
    isMainService: true,
  },
  {
    id: 3,
    tabName: 'Пародонтология',
    title: 'Пародонтология',
    text: 'Пародонтология включает в себя профилактику, диагностику и лечение заболеваний околозубных тканей и их патологий, а также поддержание здоровья, функций и эстетики этих структур.',
    link: '/adult#paradontologiya',
    image: require('./images/paradontologiya.jpg'),
    isMainService: true,
  },
  {
    id: 4,
    tabName: 'Эстетическая стоматология',
    title: 'Эстетическая стоматология',
    text: 'Эстетическая стоматология — устраняет различные дефекты внешнего вида зубов и зубного ряда. По современным стандартам красоты зубной ряд должен быть ровным, а сами зубные единицы должны быть естественного белого цвета, с микрорельефом и прозрачным режущим краем.',
    link: '/adult#estet',
    image: require('./images/estet.jpg'),
    isMainService: true,
  },
];

const terapiya = [
  {
    id: 0,
    tabName: 'Лечение под микроскопом',
    title: 'Лечение под микроскопом',
    text: 'Для работы с микроскопом в нашей стоматологической клинике оборудованы специальные кабинеты. Пациент садится в удобное кресло, к его ротовой полости направляется микроскоп, через который врач производит осмотр.',
    link: '/lechenie-pod-mikroskopom',
    image: require('./images/kb-bg-2.jpg'),
  },
  {
    id: 1,
    tabName: 'Лечение зубов',
    title: 'Лечение зубов',
    text: 'Частое потребление кофе и чая, неправильное питание, недостаток витаминов и микроэлементов в организме, неочищенная питьевая вода, неверная гигиена полости рта – от всего этого разрушаются наши зубы, образовывается налет и зубной камень, который приводит к болезням десен.',
    link: '/lechenie-zubov',
    image: require('./images/lechenie-zubov-3.jpg'),
  },
  {
    id: 2,
    tabName: 'Кариес',
    title: 'Кариес',
    text: 'Многие полагают, что кариес – это разрушение зуба, то есть появление в нем полости (дырки). Однако это не совсем так, ведь в перевозе кариес означает «гниение», соответственно, начинается он с разрушения денты, ее размягчения. В результате на эмали появляются потемнения или белые пятна.',
    link: '/karies',
    image: require('./images/karies-2.jpg'),
  },
  {
    id: 3,
    tabName: 'Стоматит',
    title: 'Стоматит',
    text: 'Стоматит могут вызвать осложнения вирусных заболеваний, невернае гигиена полости рта, прием через мерно горячих или холодных напитков, заболевания сердечно-сосудистой эндокринной системой, а также болезни ЖКТ, механические повреждения в полости рта, постоянные стрессы, нехватка витаминов...',
    link: '/stomatit-lechenie',
    image: require('./images/stomatit-2.jpg'),
  },
];

const ortoped = [
  {
    id: 0,
    tabName: 'Протезирование',
    title: 'Протезирование',
    text: 'Специалисты нашей клиники выполняют протезирование зубов любой сложности, предварительно оценив состояние полости рта больного, места, где когда-то был зуб, а теперь требуется его замена.',
    link: '/protezirovanie-zubov',
    image: require('./images/protezirovanie-2.jpg'),
  },
  {
    id: 1,
    tabName: 'Микропротезирование',
    title: 'Микропротезирование',
    text: 'Микропротезирование зубов, является достижением современной ортопедической стоматологии, основной целью которого стало максимальное снижение стоматологического воздействия на соседние здоровые...',
    link: '/mikroprotezirovanie-zubov',
    image: require('./images/mikroprotezirovanie-2.jpg'),
  },
  {
    id: 2,
    tabName: 'Мостовидные протезы',
    title: 'Мостовидные протезы',
    text: 'Мостовидные зубные протезы представляют собой конструкцию, состоящую из двух коронок, между которыми располагаются искусственные зубы. По форме такой зубной протез напоминает мост.',
    link: '/mostovidnye-zubnie-protezy',
    image: require('./images/mostovidnie-protezi-2.jpg'),
  },
  {
    id: 3,
    tabName: 'Нейлоновые протезы',
    title: 'Нейлоновые протезы',
    text: 'Гибкие нейлоновые протезы значительно отличаются от протезов с металлической конструкцией или протезов, выполненных из твердой пластмассы. Протезы старых образцов с металлическими...',
    link: '/nejlonovye-zubnye-protezy',
    image: require('./images/nejlonovye-zubnye-protezy-2.jpg'),
  },
  {
    id: 4,
    tabName: 'Бюгельные протезы',
    title: 'Бюгельные протезы',
    text: 'Бюгельный протез представляет собой съемную стоматологическую конструкцию, отличающуюся от традиционных протезов тем, что жевательная нагрузка тут распределяется по всей поверхности челюсти...',
    link: '/byugelnye-zubnye-protezy',
    image: require('./images/bugelnie-protezi-2.jpg'),
  },
  {
    id: 5,
    tabName: 'Коронки из оксида циркония',
    title: 'Коронки из оксида циркония',
    text: 'Коронка из оксида циркония обладает светопроницаемостью сходной со светопроницаемостью натуральных зубов, это делает их неотличимыми. По точности изготовления и прилегания...',
    link: '/koronki-iz-oksida-tsirkoniya',
    image: require('./images/koronki-zubnye-2.jpg'),
  },
  {
    id: 6,
    tabName: 'Люминиры',
    title: 'Люминиры',
    text: 'Люминиры - это тоненькие накладки на зубы, выполненные из фарфора. Они тоньше виниров. Их толщина составляет 0,2 мм. Люминиры изготавливаются при помощи моделирующих технологий...',
    link: '/lyuminiry',
    image: require('./images/lumin-2.jpg'),
  },
  {
    id: 7,
    tabName: 'Керамическая вкладка',
    title: 'Керамическая вкладка',
    text: 'Керамическая вкладка - это очень прочный и высокоэстетичный цельнокерамический микропротез. Если коронковая часть зуба разрушена более, чем на 1/3 то это прямое показание к изготовлению вкладки.',
    link: '/vkladka-keramicheskaya-izgotovlenie',
    image: require('./images/vkladki-keramicheskie-2.jpg'),
  },
  {
    id: 8,
    tabName: 'Культевая вкладка',
    title: 'Культевая вкладка',
    text: 'В отличае от штифтов, культевая вскладка распределяет нагрузку на всю поверхность зуба одинаково, так как она устойчиво закрепляется во всех корневых каналах. Сначала врач-стоматолог обязательно...',
    link: '/kultevaya-vkladka',
    image: require('./images/vosstanovlenie-zuba-2.jpg'),
  },
];

const khirurgiya = [
  {
    id: 0,
    tabName: 'Имплантация зубов',
    title: 'Имплантация зубов',
    text: 'Имплантация зубов - это хирургическая операция, в процессе которой стоматолог устанавливает в костную ткань имплантат, выполняющий роль фундамента для коронки или протеза. Данная манипуляция восстанавливает жевательную функцию и эстетику улыбки, возвращая пациентам уверенность в себе!',
    link: '/implantaciya',
    image: require('./images/implantatsiya-2.jpg'),
  },
  {
    id: 1,
    tabName: 'Киста зуба',
    title: 'Киста зуба',
    text: 'Киста зуба – это образование в полости рта, заполненное жидкостью. Располагается она вокруг верхней части корня зуба. Киста может долгое время не доставлять никаких неудобств, но она несет угрозу для здоровья и сохранности зуба. Полость кисты со временем понемногу увеличивается, тем самым уничтожая ткани внутри самого зуба.',
    link: '/kista-zuba',
    image: require('./images/kista-zuba-2.jpg'),
  },
  {
    id: 2,
    tabName: 'Удаление зубов',
    title: 'Удаление зубов',
    text: 'Имплантация зубов - это хирургическая операция, в процессе которой стоматолог устанавливает в костную ткань имплантат, выполняющий роль фундамента для коронки или протеза. Данная манипуляция восстанавливает жевательную функцию и эстетику улыбки, возвращая пациентам уверенность в себе!',
    link: '/udalenie',
    image: require('./images/udalenie-2.jpg'),
  },
];

const paradontologiya = [
  {
    id: 0,
    tabName: 'Пародонтоз',
    title: 'Пародонтоз',
    text: 'При неверном уходе за полостью рта может появиться зубной камень, что становится одной из наиболее распространенных причин возникновения пародонтоза, то есть воспаления дёсен.',
    link: '/lechenie-parodontoza',
    image: require('./images/paradontoz-2.jpg'),
  },
  {
    id: 1,
    tabName: 'Пародонтит',
    title: 'Пародонтит',
    text: 'Пародонтитом называют воспаление тканей, окружающих зубы. Пародонтитом страдают около 85% мужчин и женщин в возрасте от 35 лет. Разделяют на острый и хронический. Острый парадонтит случается реже.',
    link: '/parodontit',
    image: require('./images/parodontit-2.jpg'),
  },
  {
    id: 2,
    tabName: 'Лечение десен',
    title: 'Лечение десен',
    text: 'Лечение десен, зачастую, является комплексом стоматологических процедур с применением различных методов. Лечение десен начинают с тщательного обследования полости рта пациента.',
    link: '/lechenie-desen',
    image: require('./images/lechenie-desen-2.jpg'),
  },
];

const estet = [
  {
    id: 0,
    tabName: 'Чистка зубов',
    title: 'Чистка зубов',
    text: 'Профессиональная чистка зубов опытными стоматологами Дантист Плюс позволяет снизить риск возникновения кариеса, воспаления десен. С ее помощью можно быстро вернуть естественную белизну зубов, позаботиться о профилактике патологий зубочелюстной системы.',
    link: '/chistka-zubov',
    image: require('./images/chistka-2.jpg'),
  },
  {
    id: 1,
    tabName: 'Отбеливание зубов',
    title: 'Отбеливание зубов',
    text: 'Стоматология «Дантист Плюс» предлагает отбеливание зубов в Подольске по низкой цене. Мы используем инновационные методы чистки зубов, что позволяет получить высокий результат. У нас работают только дипломированные специалисты, которые готовы подсказать...',
    link: '/otbelivanie-zubov',
    image: require('./images/otbelivanie-2.jpg'),
  },
  {
    id: 2,
    tabName: 'Ультразвуковая чистка',
    title: 'Ультразвуковая чистка',
    text: 'Ультразвуковая чистка – это современный и безопасный способ профессиональной чистки зубов, с которого должно начинаться любое лечение в полости рта. С помощью специального аппарата (скалера), с поверхности зубов удаляется налет и зубной камень, очень деликатно, не нанося вреда.',
    link: '/ultrazvukovaya-chistka',
    image: require('./images/ultra-chistka-2.jpg'),
  },
  {
    id: 3,
    tabName: 'Чистка зубов Air-Flow',
    title: 'Чистка зубов Air-Flow',
    text: 'Airflow - это один из самых безопасных и быстрых методов удаления зубных отложений. Такую процедуру отбеливания зубов точнее было бы назвать косметической очисткой, так как в процессе использования Air flow происходит очищение эмали от налета, зубных отложений и различных бактерий.',
    link: '/air-flow',
    image: require('./images/chistka-air-flow-2.jpg'),
  },
  {
    id: 4,
    tabName: 'Брекеты',
    title: 'Брекеты',
    text: 'Брекеты представляют собой небольшие тонкие пластины, которые крепятся на зубы посредством тонкой проволоки. Они позволяют решить проблему неровности зубов как врожденные, так и приобретенные вследствие определенных травм, механических повреждений...',
    link: '/brekety-zubov-tsena-ustanovka',
    image: require('./images/brekety-2.jpg'),
  },
];

const child = [
  {
    id: 0,
    tabName: 'Лечение кариеса',
    title: 'Лечение кариеса',
    text: 'Кариес в раннем детском возрасте, является заболеванием, которое поражает зубы у детей в возрасте от рождения до 71 месяца и характеризуется наличием 1 или более разрушенных, отсутствующих или пломбированных поверхностей зуба в любом первичном зубе.',
    link: '/detskaya-stomatologiya',
    image: require('./images/karies-detskiy.jpg'),
  },
  {
    id: 1,
    tabName: 'Удаление молочного зуба',
    title: 'Удаление молочного зуба',
    text: 'Стоматологи крайне не рекомендуют торопить события с удалением моточного зуба. Зубам обязательно нужно помочь выпасть если: кариес повредил больше половины зуба, неудобно жевать, острый край царапает щеку или язык, пульпит, на слизистой оболочке есть воспаление или киста.',
    link: '/detskaya-stomatologiya',
    image: require('./images/udalenie-molochnogo-zuba.jpg'),
  },
  {
    id: 2,
    tabName: 'Детская ортодонтическая брекет-система',
    title: 'Детская ортодонтическая брекет-система',
    text: 'Ортодонты рекомендуют ношение брекет-системы с 14-16 лет. Так как: прикус уже сменился и выросли постоянные зубы, лицевые кости еще растут и перемещение зубов в правильное положение происходит быстрее, мышцы пластичны — быстрее меняют точки воздействия при смене прикуса.',
    link: '/detskaya-stomatologiya',
    image: require('./images/breket-detskiy.jpg'),
  },
  {
    id: 3,
    tabName: 'Чистка зубов',
    title: 'Чистка зубов',
    text: 'При неправильной гигиене, зубной налет скапливается и уплотняется, превращаясь в зубной камень, который является источником инфекций. Десны воспаляются и ребенок во время чистки избегает болезненный участок, поэтому проблема лишь ухудшается.',
    link: '/detskaya-stomatologiya',
    image: require('./images/chistka-detskaya.jpg'),
  },
];

const reviews = [
  {
    id: 0,
    name: 'Суворова Марина',
    text: 'Большое спасибо стоматологии "Дантист плюс" за лечение! Изначально выбрала Вас из-за недорогих, по сравнению с московскими, цен. Теперь буду рекомендовать Вас всем своим друзьям! Доктор не только быстро и безболезненно вылечил зуб, но и наглядно, на мониторе, показал процесс работы!',
    date: '13.05.2016',
  },
  {
    id: 1,
    name: 'Комарова Елизавета',
    text: 'Уже первая консультация по установке брекетов внушила доверие к врачу, и цены приятно удивили!',
    date: '13.05.2016',
  },
  {
    id: 2,
    name: 'Вероника',
    text: 'Очень хорошие врачи и клиника замечательная! Правда она на первый взгляд не взрачная, но внутри все хорошо! Особенно очень понравилась девушка на регистратуре!! Встретила с улыбкой, что очень приятно стало. Врачи очень грамотные специалисты. Спасибо Вам большое!',
    date: '13.05.2016',
  },
  {
    id: 3,
    name: 'Тамара ',
    text: 'С детства боюсь врачей стоматологов. Терплю до последнего ,пока зуб не отвалится) Первая клиника из которой я вышла без слез,совсем не больно,обстановка спокойная и домашняя ,совсем не наводит панику. Спасибо Вам большое!',
    date: '13.05.2016',
  },
  {
    id: 4,
    name: 'Елена Михайловна',
    text: 'Выражаю огромную благодарность Волостнову Льву Геннадьевичу за высокое качество работы! Лечили зубы у этого доктора всей семьей. Очень довольны его работой. Пломбы стоят долго, еще ни разу не выпадали. Свою работу он выполняет профессионально и качественно, всегда старается сохранить зуб. Заранее знайте, что все будет хорошо. Лев Геннадьевич врач от Бога! Здоровья Вам, Вашей семье и всем сотрудникам стоматологической клиники "Дантист плюс"!',
    date: '13.05.2016',
  },
  {
    id: 5,
    name: 'Марина Юрьевна',
    text: 'Цены ниже, качество чувствуется. Год назад делала передний зуб в Лайме в Климовске за 2700 рублей, а он снова почернел. В Вашей клинике объяснили про гарантию и завели карту, оплатила 2450 рублей. Уверенна, что залечен зуб правильно! Спасибо, Дантист плюс!!',
    date: '13.05.2016',
  },
  {
    id: 6,
    name: 'Серёга',
    text: 'Очень рекомендую! Контора что надо!',
    date: '13.05.2016',
  },
  {
    id: 7,
    name: 'Трусливый пациент',
    text: 'Нина Викторовна!!! Спасибо что вы есть. Спасибо за ваше доброе и чуткое отношение, профессионализм . Спасибо, что было совсем не больно. Вы самый лучший стоматолог в мире и профессионал в своем деле. Теперь лечить зубы я не боюсь.',
    date: '13.05.2016',
  },
  {
    id: 8,
    name: 'Ольга',
    text: 'Собираюсь на прием. Позже напишу об ощущениях!',
    date: '13.05.2016',
  },
  {
    id: 9,
    name: 'Олег',
    text: 'Что-то не пишите об ощущениях. Или всё плохо?',
    date: '13.05.2016',
  },
  {
    id: 10,
    name: 'Виталий',
    text: 'Я могу зато написать! С детства боюсь стоматологов. Был вынужден идти по экстренному поводу. Опухоль при флюсе и поспаление разрушенных корней. Сделали классно! Врач - Понизова Нина Викторовна! Рекомендую всем.Огромное облегчение за разумные деньги! До этого у меня был врач - брал вдвое больше за некачественную работу. Спасибо еще раз!',
    date: '13.05.2016',
  },
  {
    id: 11,
    name: 'Валентина Архиповна',
    text: 'Вот уже почти восемь лет я и моя семья пользуемся услугами этой стоматологии. Каждый год хожу на профилактически й осмотр. Хочу поделиться своими положительными эмоциями. За этот длительный период нашего знакомства я всегда остаюсь довольна работой, вниманием и отношением. Администратор встречает клиентов со светлой улыбкой, что в наше время встретишь не часто, работу выполняют профессионально и по доступным ценам. Я хотела бы пожелать Павлу Михайловичу и всей его команде, успехов в карьерном росте и всего самого наилучшего!',
    date: '13.05.2016',
  },
  {
    id: 12,
    name: 'Мария',
    text: 'Присоединяюсь! И спасибо, что в праздники работали!',
    date: '13.05.2016',
  },
  {
    id: 13,
    name: 'Николай Стеценко',
    text: 'В Подольске живу около года. Начал беспокоить зуб и возник вопрос в какую стоматологию обратиться. Очень переживал по этому поводу. Изучив множество клиник и отзывов через интернет , остановился на Дантист Плюс. В первую очередь мне понравилась врач Понизова Нина Викторовна (большой опыт работы), во вторых очень уютная атмосфера самой клиники. Позвонил, записался ,полечил зуб (чистка 4 каналов и пломба), остался очень доволен. Зуб как новенький. Врач очень внимательна, работает быстро, качественно и профессионально . Буду и в дальнейшем лечить зубы в Дантист Плюс и советовать своим знакомым.',
    date: '13.05.2016',
  },
  {
    id: 14,
    name: 'Гость',
    text: 'Почему все отзывы от 13.05.2016???',
    date: '05.07.2016',
  },
  {
    id: 15,
    name: 'Администратор сайта',
    text: 'Все отзывы от 13.05.2016 т.к. этим числом проводилось полное обновление сайта.',
    date: '11.07.2016',
  },
  {
    id: 16,
    name: 'Игорь Анатольевич',
    text: 'почитал отзывы, решил обратится по поводу протезирования, 22.09 консультация прошла прекрасно, всё устроило, все вежливые профессионалы. 23.09 пришёл на подготовку к протезированию, врач опоздала на 20 минут, извинения не в моде. после изучения карты вопрос к медсестре: и что мне делать, здесь полный рот зубов. Видимо не все сотрудники ценят свою работу.',
    date: '23.09.2016',
  },
  {
    id: 17,
    name: 'Павел Михайлович',
    text: 'Игорь Анатольевич, приношу Вам извинение за опоздание врача. Настоятельно рекомендовал ему позвонить Вам лично и извиниться. С Ув.Генеральный директор ООО "Дантист плюс" П.М.Коцемир.',
    date: '23.09.2016',
  },
  {
    id: 18,
    name: 'Анна',
    text: 'Выражаю огромную благодарность Ивану Николаевичу, за успешное и абсолютно БЕЗБОЛЕЗНЕННОЕ удаление 8-ки!!! У доктора золотые руки, не иначе, теперь только к нему.',
    date: '27.01.2017',
  },
  {
    id: 19,
    name: 'Марина',
    text: 'Пожалуйста, опубликуйте информацию о всех врачах, ведущих прием, и настораживает, что мало отзывов. Всё-таки клиенты выбирают, ориентируясь не только на цену. Была однажды - думаю, пользоваться ли услугами вашей стоматологии по решению более серьезных проблем. В целом впечатление хорошее.Спасибо!',
    date: '12.02.2017',
  },
  {
    id: 20,
    name: 'Павел Михайлович',
    text: 'Марина, добрый вечер, спасибо за отзыв! Приняли к сведению. На данный момент нами подготавливается информация, в ближайшее время будет опубликована.',
    date: '12.02.2017',
  },
  {
    id: 21,
    name: 'Евгения',
    text: 'Очень аккуратно и качественно и самое главное быстро и безболезненно все сделали! Второй раз посещаю вашу клинику и остаюсь очень довольна!',
    date: '13.02.2017',
  },
  {
    id: 22,
    name: 'Валерия мазурова',
    text: 'добрый вечер!Хотела выразить огромную благодарность врачу Тюрикову Ивану Николаевичу ,замечательный врач с золотыми руками,сделал все на высшем уровне ,удалил 2корня ,не смотря на то что я трусиха я вообще ничего не почувствовала,и решилась удалить ещё зуб один сразу.Большое спасибо Вам ещё раз ,Я очень довольна клиникой ,все на высшем уровне.',
    date: '04.04.2017',
  },
  {
    id: 23,
    name: 'Евгения Перегудова',
    text: 'Хочу выразить огромную благодарность доктору Тюрикову Ивану Николаевичу! Быстро, без боли удалит даже самый сложный зуб, при этом не "распахав" десну, как мне сделали в другой клинике. Коронки тоже ставит шикарные. Отличный доктор. Клиника тоже очень приятная и цены адекватные. Всем рекомендую.',
    date: '27.05.2017',
  },
  {
    id: 24,
    name: 'Николай',
    text: 'Очень сильно боюсь удалять зуб до обморока что делать подскажите',
    date: '29.05.2017',
  },
  {
    id: 25,
    name: 'Жук София Дмитриевна',
    text: 'Хочу выразить благодарность, признательность, уважение врачу Тюрикову Ивану Николаевичу за его профессионализм, чуткое отношение к пациентам, готовность помочь в любых ситуациях и решить проблемы, связанные с его профессиональной деятельностью! Огромное Вам спасибо!',
    date: '04.06.2017',
  },
  {
    id: 26,
    name: 'Марина',
    text: 'Три раза лечила зубы у Нины Викторовны. Очень хороший врач! Всем советую, абсолютно не страшно и надежно)',
    date: '30.06.2017',
  },
  {
    id: 27,
    name: 'Евгений',
    text: 'Спасибо Ивану Николаевичу! Профессионал с большой буквы. Даже не думайте, записываться, или нет. Смело приходите на прием.',
    date: '14.07.2017',
  },
  {
    id: 28,
    name: 'сергей',
    text: 'А почему все отзывы датированы маем 2016-го? Очень странно... Разве после этого других отзывов не было?',
    date: '18.07.2017',
  },
  {
    id: 29,
    name: 'Администратор сайта',
    text: 'Первые отзывы датированы от 13.05.2016 т.к. этим числом проводилось полное обновление сайта.',
    date: '18.07.2017',
  },
  {
    id: 30,
    name: 'Алла',
    text: 'Поддерживаю! Сегодня сама была на приеме, очень рекомендую доктора! Только к Ивану Николаевичу',
    date: '20.07.2017',
  },
  {
    id: 31,
    name: 'Евгений',
    text: 'отличное обслуживание всем рекомендую!',
    date: '09.10.2017',
  },
  {
    id: 32,
    name: 'Ирина',
    text: 'Тюриков Иван Николаевич-замечательный врач,интеллигентный человек,все бы такими были...',
    date: '09.10.2017',
  },
  {
    id: 33,
    name: 'юленька',
    text: 'Искренне поздравляю Вас с Новым годом! Желаю Вам в грядущем году быть в окружении исключительно положительных и доброжелательных людей, переживать только приятные эмоции, радоваться каждому прожитому дню, дарить радость и улыбки окружающим. И пусть этот Новый год станет для Вас особенным.павлу михайловичу коцемир',
    date: '22.12.2017',
  },
  {
    id: 34,
    name: 'Пациент',
    text: 'Спасибо, Иван Николаевич! Спасли. Всем советую этого доктора!! ',
    date: '14.03.2018',
  },
  {
    id: 35,
    name: 'Светлана',
    text: 'Пожалуйста , подскажите , кто-нибудь ставил в этой стоматологии импланты ,интересует результат, цена и к какому врачу лучше обращаться, спасибо.',
    date: '22.04.2018',
  },
  {
    id: 36,
    name: 'Про100 клиент',
    text: 'Живу недалеко. Много лет проходила мимо, обращаясь лишь за консультациями иногда (без оплаты тут это делают профессионально) в госстоматологию. А тут, можно сказать "приспичило". Была на удивление поражена тем, что чувствовала. Доктор дотрагивалась своими приборами до зуба так, что я получала наслаждение. Это впервые за свою многолетнюю практику лечения зубов. Тут ощущение, что ты про100 пришёл в гости на "огонёк", а тебе ещё и подарили удовольствие от лечения. Поменялось мировоззрение кардинально. Буду копить деньги на лечение у них. Когда построим на Земле Рай, думаю у таких профессионалов вообще всё будет, что они хотят... Радости вам, самых теплых отзывов в этом году, сердечности от клиентов, и низкий вам поклон за способность быть светлыми, улыбчивыми и человечными! С уважением, Про100 клиент',
    date: '19.02.2019',
  },
  {
    id: 37,
    name: 'Лариса Александровна',
    text: 'Спасибо Ивану Николаевичу Тюрикову за протезирование.Замечательный доктор, золотые руки, работает, как ювелир! Спасибо Нине Викторовне Понизовой,лечит зубы отлично! Спасибо девочкам администраторам, улыбчивые, добрые! Весь коллектив замечательный!Всем рекомендую эту клинику!',
    date: '26.04.2019',
  },
  {
    id: 38,
    name: 'Лариса Александровна',
    text: 'Спасибо Ивану Николаевичу Тюрикову за работу со мной.Сделали импланты и мост отлично!Это замечательный доктор,золотые руки,работает как ювелир!Спасибо Нине Викторовне Понизовой,профессионал!Хорошая клиника,всем рекомендую.',
    date: '15.05.2019',
  },
  {
    id: 39,
    name: 'Елена',
    text: 'Клинику хорошая , врачи молодцы. Рекомендую',
    date: '10.03.2020',
  },
  {
    id: 40,
    name: 'Татьяна',
    text: 'Хочу выразить благодарность Нине Викторовне. Высококлассный специалист! Огромное спасибо за Вашу работу!',
    date: '25.07.2020',
  },
  {
    id: 41,
    name: 'Людмила',
    text: 'Спасибо огромное Кадзаевой Александре Сергеевне, отреставрировала мне 8 зубов, теперь у меня красивая улыбка. Всем друзьям буду рекомендовать. Много в какие клиники обращалась, но тут просто результатом довольна как слон. Очень эстетично всё сделала. Огромное ещё раз спасибо🙏👌🤗',
    date: '01.04.2022',
  },
  {
    id: 42,
    name: 'Михайлова Нина Александровна',
    text: 'Больше спасибо Понизовой Нине Викторовне за профессионализм и чуткое отношение к пациентам.',
    date: '31.05.2023',
  },
  {
    id: 43,
    name: 'Геннадий',
    text: 'Отличная работа высококлассных специалистов! Рекомендую! Спасибо большое!',
    date: '01.06.2023',
  },
  {
    id: 44,
    name: 'Галина',
    text: 'Спасибо стоматологам клиники. Пользуюсь их услугами более 10 лет. И очень довольна. Спасибо Нине Викторовне и Игорю Алексеевичу.',
    date: '02.06.2023',
  },
  {
    id: 45,
    name: 'Данзанов Тумэн',
    text: 'Случайно зашел в «ДАНТИСТ ПЛЮС», был приятно удивлен внимательностью и профессионализмом коллектива. Отдельное спасибо Понизовой Нине Викторовне. Во время лечения чувствуешь, что находишься в руках профессионалов своего дела.',
    date: '02.06.2023',
  },
  {
    id: 46,
    name: 'Татьяна Ефремова',
    text: 'Услугами клиники пользуюсь около 8 лет, всем очень довольна, цена соответствует качеству, после беременности все зубы посыпались, доктор Нина Викторовна проводила лечение порядка 12 зубов, + устанавливали коронки, все очень качественно и деликатно, в какой то момент даже проваливалась в сон. Рекомендую клинику, сейчас каждый год хожу на плановый осмотр и чистку, следить за зубами стало даже приятно, отличные врачи и персонал. Спасибо.',
    date: '04.06.2023',
  },
  {
    id: 47,
    name: 'Людмила',
    text: 'Добрый день! Лечу зубы у Понизовой Нины Викторовны. Очень довольна. Внимательный и профессиональный специалист. Рекомендую.',
    date: '06.06.2023',
  },
  {
    id: 48,
    name: 'Юлия Недведь',
    text: 'Здравствуйте, огромное спасибо Понизовой Нине Викторовна за её работу с моими зубами. Всё быстро и эффективно. Рассказала во время приёма что буделать что надо будит делать если боль придёт после приёма. Огромное спасибо персоналу.',
    date: '11.06.2023',
  },
  {
    id: 49,
    name: 'Елена Букатина',
    text: 'Врач-ортопед Лукъянчиков Игорь Алексеевич выполнил мне съёмный зубной протез для верхней челюсти. Благодарю врача за добросовестную и грамотную работу. Я очень довольна качеством и быстротой исполнения работы. Я долго выбирала врача и клинику, где можно решить свои проблемы с зубами и, даже, имею негативный опыт в других клиниках. В дальнейшем буду лечить и протезировать зубы только в этой клинике и только у Лукъянчикова И.А. Рекомендую этого замечательного врача и клинику другим людям, имеющим проблемы с зубами. Благодарю!🙏🏻❤️',
    date: '14.06.2023',
  },
  {
    id: 50,
    name: 'Юлия',
    text: 'Лечимся с дочками в этой клинике уже несколько лет. Здесь работают замечательные доктора. Младшая дочка лечит зубки у Кадзаевой Александры Сергеевны. Идёт без страха, даже с радостью. Потому что не больно и не страшно. Спасибо за внимательное отношение!',
    date: '16.06.2023',
  },
  {
    id: 51,
    name: 'Александр',
    text: 'Здравствуйте, прекрасная стоматология,профессиональные специалисты. Я и моя супруга пользуемся услугами этой стоматологии',
    date: '22.06.2023',
  },
  {
    id: 52,
    name: 'Александр Коваль',
    text: 'Спасибо большое Александре Сергеевне и Игорю Алексеевичу за качественную работу, спасибо всему дружному коллективу. буду рекомендовать их, ну и сам даст Бог буду долечивать остальные зубы. цены приемливые, ни кто не навязывает доп работы, хочешь делай хочешь нет.. Всех благ всем добрым людям.',
    date: '23.06.2023',
  },
  {
    id: 53,
    name: 'Олег',
    text: 'Здравствуйте! Понизовой Нине Викторовне большое спасибо! Доволен лечением зубов. Спасибо персоналу клиники!',
    date: '24.06.2023',
  },
  {
    id: 54,
    name: 'Родион',
    text: 'САМАЯ ЛУЧШАЯ СТОМАТОЛОГИЯ!!!Советую, записывался к Нине Викторовне всегда предупредит что делает. Ювелирная работа. Лично мне ставили пломбу зуб как новый!!! СОВЕТУЮ! РОДИОН 11ЛЕТ',
    date: '27.06.2023',
  },
  {
    id: 55,
    name: 'Зинаида',
    text: 'Лечу зубы в этой стоматологии уже давно,все отлично.',
    date: '29.06.2023',
  },
  {
    id: 56,
    name: 'Марина Александровна',
    text: 'Посещаю данную клинику почти год. Здесь профессиональные специалисты и доброжелательный и отзывчивый персонал.Отдельное огромное спасибо Понизовой Нине Викторовне. Долго искала, кому можно доверить свои зубы и очень благодарна, что есть люди, которым можно довериться. Качественная работа! Золотые руки!',
    date: '04.08.2023',
  },
  {
    id: 57,
    name: 'Василиса Алексеевна Захарова',
    text: 'Хочу оставить свой отзыв о посещении стоматологии "Дантист плюс". Начиная со встречи администратора, все было на высоте! Приветливая девушка все объяснила, быстро все оформила. Особенно хочу сказать о работе Кадзаевой Александры Сергеевны - очень понравилось что она все сделала профессионально, быстро и комфортно (насколько это возможно в данной ситуации) процедура не из приятных, но благодаря мастерству и чуткости доктора все прошло очень хорошо! Так же благодарю милую девушку, которая помогала (к сожалению не знаю, как ее зовут)! Девочки! У вас золотые руки! Благодарю! зубы в этой стоматологии уже давно,все отлично.',
    date: '04.08.2023',
  },
  {
    id: 58,
    name: 'Василиса',
    text: 'Хочу поблагодарить Александру Сергеевну за профессионализм и чуткость, прекрасная работа! Отреставрировала мой зуб! Стал лучше прежнего! Также хочу поблагодарить милую девушку, которая ассистировала и и девушку - администратора - девчонки, вы лучшие!',
    date: '04.08.2023',
  },
  {
    id: 59,
    name: 'Елена',
    text: 'Пользуюсь услугами клиники уже более 10 лет. Нина Викторовна замечательный врач, профессионал своего дела, лечит зубы очень качественно, так же обращалась к Игорю Алексеевичу. Ставили коронку и имплант, всё замечательно без боли, без негативных последствий, всё чётко и быстро. Спасибо Вам огромное за ваш профессионализм, за чуткость и внимательность к пациентам. Всем рекомендую обращаться в клинику Дантист плюс',
    date: '11.11.2023',
  },
];

export {
  AppRoute,
  startDate,
  yearDecl,
  monthDecl,
  dayDecl,
  hourDecl,
  minuteDecl,
  seconsDecl,
  services,
  terapiya,
  ortoped,
  khirurgiya,
  paradontologiya,
  estet,
  child,
  reviews,
  doctors
};
