import React from 'react';
import PropTypes from 'prop-types';
import styles from './review.module.scss';

function Review({ review }) {

  const { name, text, date } = review;
  return (
    <div className={styles.container}>
      <p className={styles.name}>{name}</p>
      <p className={styles.date}>{date}</p>
      <p className={styles.text}>{text}</p>
    </div>
  );
}

Review.propTypes = {
  review: PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }),
};

export default Review;
