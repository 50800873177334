import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import Header from '../header/header';
import YandexMap from '../yandex-map/yandex-map';
import OrderBottom from '../order-bottom/order-bottom';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import Footer from '../footer/footer';
import styles from './spetsialisty-page.module.scss';
import ponizova from '../../images/nina-viktorovna2.jpg';
import levitanskii from '../../images/levitanskiy.jpg';
import kadzaeva from '../../images/kadzaeva.jpg';
//import losev from '../../images/losev.jpg';
import lukyanchikov from '../../images/lukyanchikov.jpg';
//import rahnyanskaya from '../../images/rahnyanskaya.jpg';
import sadohina from '../../images/sadohina.jpg';
import pugacheva from '../../images/pugacheva.jpg';
import ionova from '../../images/ionova.jpg';
import sazonova from '../../images/sazonova.jpg';
import sivirchukov from '../../images/sivirchukov.jpg';
import matchanova from '../../images/matchanova.jpg';
import budei from '../../images/budei.jpg';
import kondakova from '../../images/kondakova.jpg';

function SpetsialistyPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className={styles.title}>Специалисты клиники «Дантист Плюс» в Подольске</h1>
        <div className='container'>
          <ul className={styles.list}>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Понизова Нина Викторовна</h3>
                  <p className={styles.speciality}>Врач стоматолог-терапевт, стоматолог детский</p>
                  <p className={styles.experience}>Стаж работы 31 год</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>1984 - 1989 "Московский медицинский стоматологический институт им.Семашко"</li>
                  </ul>
                  <p className={styles.text}>Основные направления: терапия в полном объёме (взрослая и детская), реставрации, эндодонтическое лечение.</p>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={ponizova} alt='Понизова Нина Викторовна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Черхавская (Кадзаева) Александра Сергеевна</h3>
                  <p className={styles.speciality}>Главный врач, стоматолог-терапевт, стоматолог детский</p>
                  <p className={styles.experience}>Стаж работы 7 лет</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>2013 "Российский Национальный Исследовательский Медицинский Университет им.Н.И.Пирогова." Стоматологический факультет</li>
                  </ul>
                  <p className={styles.text}>Основные направления: терапия в полном объёме (взрослая и детская), реставрации, эндодонтическое лечение, врач – микроскопист.</p>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={kadzaeva} alt='Кадзаева Александра Сергеевна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Левитанский Максим Евгеньевич</h3>
                  <p className={styles.speciality}>Врач стоматолог-хирург, имплантолог</p>
                  <p className={styles.experience}>Стаж работы 17 лет</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>2001 - 2006 – «Тверская государственная медицинская академия Федерального агентства по здравоохранению и социальному развитию», специализация «Стоматология»</li>
                    <li className={styles.diploma}>2006 - 2008 - Стажировка в Израиле.</li>
                    <li className={styles.diploma}>2008 - по настоящее время практикующий хирург в собственной клинике в Израиле.</li>
                  </ul>
                  <p className={styles.text}>Основные направления: Хирургия в полном объеме, костная пластика, имплантация</p>
                  <p className={styles.sub_text}><b>Дополнительное образование:</b></p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>Действующий член Международной ассоциации имплантологов ICOI</li>
                    <li className={styles.diploma}>Действующий член Израильской ассоциации имплантологов ICOC</li>
                  </ul>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={levitanskii} alt='Левитанский Максим Евгеньевич' width="1200" height="1800" />
              </div>
            </li>
            {/* <li className={styles.item}>
							<div className={styles.container}>
								<div className={styles.description}>
									<h3 className={styles.name}>Лосев Евгений Александрович</h3>
									<p className={styles.speciality}>Врач стоматолог-ортопед, стоматолог-хирург</p>
									<p className={styles.experience}>Стаж работы 18 лет</p>
									<ul className={styles.diplomas}>
										<li className={styles.diploma}>2002 "Московский Государственный медико-стоматологический университет"</li>
									</ul>
									<p className={styles.text}>Основные направления: все виды протезирования, коронки на имплантатах, люминиры, виниры, микропротезирование, безметалловая керамика и т.д</p>
									<Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
								</div>
								<img className={styles.image} src={losev} alt='Лосев Евгений Александрович' width="1200" height="1800" />
							</div>
						</li> */}
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Лукьянчиков Игорь Алексеевич</h3>
                  <p className={styles.speciality}>Врач стоматолог-хирург, пародонтолог, стоматолог-ортопед</p>
                  <p className={styles.experience}>Стаж работы 8 лет</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>2007-2012 "Курский Государственный Медицинский Университет"</li>
                  </ul>
                  <p className={styles.text}>Основные направления: Хирургия в полном объеме, удаления любой сложности, пародонтология. Все виды съемного и несъемного протезирования. Протезирование на имплантатах.</p>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={lukyanchikov} alt='Лукьянчиков Игорь Алексеевич' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Сивирчуков Виталий Николаевич</h3>
                  <p className={styles.speciality}>Врач стоматолог, терапевт</p>
                  <p className={styles.experience}>Стаж работы 16 лет</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>2007 "Волгоградский медицинский колледж"</li>
                    <li className={styles.diploma}>2007 ординатура "Государственное образовательное учреждение здравоохранения "Урюпинское медицинское училище №1". " Факультет Стоматологический. Специализация Зубной врач.</li>
                    <li className={styles.diploma}>2015 "Московский Государственный Медико-Стоматологический Университет" (МГМСУ), стоматологический факультет</li>
                    <li className={styles.diploma}>2016 интернатура "стоматология общей практики" МГМСУ</li>
                  </ul>
                  <p className={styles.text}>Основные направления: терапия в полном объёме, реставрации, эндодонтическое лечение. </p>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={sivirchukov} alt='Сивирчуков Виталий Николаевич' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Матчанова Оксана Батыровна</h3>
                  <p className={styles.speciality}>Врач стоматолог-терапевт, пародонтолог</p>
                  <p className={styles.experience}>Стаж работы 28 лет</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>1995 "Ташкентский медицинский институт". Специализация стоматология, базовое образование</li>
                    <li className={styles.diploma}>1996 "Ташкентский медицинский институт". Специализация стоматология терапевтическая, интернатура</li>
                  </ul>
                  <p className={styles.text}>Основные направления: терапия в полном объеме, реставрации, эндодонтическое лечение.</p>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={matchanova} alt='Матчанова Оксана Батыровна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Будей Игорь Васильевич</h3>
                  <p className={styles.speciality}>Врач стоматолог-ортопед</p>
                  <p className={styles.experience}>Стаж работы 10 лет</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>2013 "Государственный университет медицины и фармакологии имени Николая Тестемицану". Специализация стоматология, базовое образование</li>
                    <li className={styles.diploma}>2014 "Первый московский государственный медицинский университет им. И.М. Сеченова". Специализация стоматология общей практики, интернатура</li>
                    <li className={styles.diploma}>2015 Циклы переподготовки</li>
                  </ul>
                  <p className={styles.text}>Основные направления: функциональная и эстетическая реабилитация зубного ряда. Ортопедия в полном объеме: протезирование съемными и несъемными конструкциями (безметалловая керамика, металлокерамика), протезирование на имплантах.</p>
                  <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link>
                </div>
                <img className={styles.image} src={budei} alt='Будей Игорь Васильевич' width="1200" height="1800" />
              </div>
            </li>
            {/* <li className={styles.item}>
							<div className={styles.container}>
								<div className={styles.description}>
									<h3 className={styles.name}>Рахнянская Юлия Юрьевна</h3>
									<p className={styles.speciality}>Ассистент врача-стоматолога</p>
									<ul className={styles.diplomas}>
										<li className={styles.diploma}>1995-1997 "Мариупольское медицинское училище"</li>
									</ul>
								</div>
								<img className={styles.image} src={rahnyanskaya} alt='Рахнянская Юлия Юрьевна' width="1200" height="1800" />
							</div>
						</li> */}
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Садохина Ольга Александровна</h3>
                  <p className={styles.speciality}>Ассистент врача-стоматолога</p>
                  <ul className={styles.diplomas}>
                    <li className={styles.diploma}>2009-2013 "Вяземский Медицинский колледж"</li>
                  </ul>
                </div>
                <img className={styles.image} src={sadohina} alt='Садохина Ольга Александровна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Пугачева Жанна Валерьевна</h3>
                  <p className={styles.speciality}>Ассистент врача-стоматолога</p>
                </div>
                <img className={styles.image} src={pugacheva} alt='Пугачева Жанна Валерьевна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Кондакова Наталья Валерьевна</h3>
                  <p className={styles.speciality}>Ассистент врача-стоматолога</p>
                </div>
                <img className={styles.image} src={kondakova} alt='Пугачева Жанна Валерьевна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Сазонова Светлана Сергеевна</h3>
                  <p className={styles.speciality}>Администратор</p>
                </div>
                <img className={styles.image} src={sazonova} alt='Сазонова Светлана Сергеевна' width="1200" height="1800" />
              </div>
            </li>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>Ионова Татьяна Андреевна</h3>
                  <p className={styles.speciality}>Администратор</p>
                </div>
                <img className={styles.image} src={ionova} alt='Ионова Татьяна Андреевна' width="1200" height="1800" />
              </div>
            </li>
          </ul>
        </div>
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default SpetsialistyPage;
