import React from 'react';
import cn from 'classnames';
import logo from '../../images/logo-footer.png';
import styles from './footer.module.scss';

function Footer() {

  return (
    <footer>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <img src={logo} width="270" height="55" aria-label="Стоматология в Подольске Дантист Плюс" />
          <iframe className={styles.place} src="https://yandex.ru/sprav/widget/rating-badge/1084471890?type=award" width="150" height="50" frameBorder="0" title="place"></iframe>
        </div>
        <div className={styles.container}>
          <h3 className={styles.title}>Режим работы:</h3>
          <p className={styles.text}><b>Пн-Пт</b> 10:00-21:00</p>
          <p className={styles.text}><b>Сб</b> 10:00-20:00</p>
          <p className={styles.text}><b>Вс</b> 10:00-17:00</p>
          {/* <p className={cn(styles.text, styles.text_red)}>1 - 3, 5, 9 мая  - выходной!</p> */}
        </div>
        <div className={styles.container}>
          <h3 className={styles.title}>Контакты</h3>
          <a className={styles.link} href="tel:+74967524444">+7 (4967) 52-44-44</a>
          <a className={styles.link} href="tel:+79651978020">+7 (965) 197-80-20</a>
          <a className={styles.link} href="mailto:info@dant-plus.ru">info@dant-plus.ru</a>
        </div>
        <div className={styles.container}>
          <h3 className={styles.title}>Соц. сети</h3>
          <a className={cn(styles.link, styles.vk)} href="https://vk.com/stomatologiya_v_podolske" target="_blank" rel="noreferrer">Vk</a>
          <a className={cn(styles.link, styles.instagram)} href="https://www.instagram.com/dantplus_podolsk/" target="_blank" rel="noreferrer">Instagram</a>
          <a className={cn(styles.link, styles.whatsapp)} href="https://wa.me/79651978020" target="_blank" rel="noreferrer">Whatsapp</a>
          <a className={cn(styles.link, styles.telegram)} href="https://t.me/+79651978020" target="_blank" rel="noreferrer">Telegram</a>
        </div>
      </div>
      <p className={styles.copyright}>Стоматология &quot;Дантист Плюс&quot; в Подольске - Copyright © 2024</p>
      <p className={styles.warning}>Имеются противопоказания. Необходимо проконсультироваться со специалистом</p>
    </footer>

  );
}

export default Footer;
