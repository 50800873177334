import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './karies.module.scss';

function Karies() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Лечение кариеса – предупредить проблему на ранней стадии</h1>
						<p>Лечение кариеса в стоматологической клинике «Дантист Плюс» в Подольске осуществляется на современном оборудовании с использованием инновационных материалов.</p>
						<p>Мы используем бинокуляры, которые помогают нам выявить кариес на ранней стадии в виде микродырочки или пятна на эмали, поэтому систематическое посещение нашей стоматологии в целях профилактики позволит сохранить здоровье каждого зуба.</p>
						<p>Мы стремимся к тому, чтобы всегда выходили от нас с улыбкой, поэтому предоставляем услуги без боли на самом высшем уровне.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Виды кариеса и способы лечения</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Многие полагают, что кариес – это разрушение зуба, то есть появление в нем полости (дырки). Однако это не совсем так, ведь в перевозе кариес означает «гниение», соответственно, начинается он с разрушения денты, ее размягчения. В результате на эмали появляются потемнения или белые пятна.</p>
							<p>После осмотра врач стоматолог определяет, какой вид назначить. Причем в ряде случаев достаточно произвести реминерализацию – восстановление эмали, чтобы остановить процесс гниения, а соответственно, спасти зуб от дальнейшего разрушения и вмешательству врачей.</p>
							<p>Перед тем, как начать лечение кариеса, стоматолог проводит обследование всей полости, чтобы выявить существующие проблемы. Это дает возможность не только отреагировать на жалобы пациента, но и предупредить их появление.</p>
						</div>
					</div>
					<p>Лечение кариеса проводится, исходя из степени запущенности болезни, то есть вида кариеса. Различают кариес:</p>
					<p className={styles.bold_text}>По стадии развития</p>
					<ul>
						<li>Поверхностный (на стадии пятна). Лечение проводится поверхностное, направленное на отвердевание зубной ткани.</li>
						<li>Средний. Требует пломбирования для того, чтобы остановить процесс разрушения зуба.</li>
						<li>Глубокий. Часто сопровождается пульпитом и воспалением или гниением корня, что сопровождается сильной болью. Лечение будет направлено на удаление корня, лечение каналов и пломбирование зуба.</li>
					</ul>
					<p className={styles.bold_text}>По месту появления</p>
					<ul>
						<li>Фисурный. Между буграми зуба.</li>
						<li>Пришеечный. Близко к десне.</li>
						<li>Прикорневой. Часто незаметный при обычном осмотре. Для его выявления следует сделать рентгеновский снимок.</li>
					</ul>
					<p className={styles.bold_text}>Интенсивности</p>
					<ul>
						<li>Острый. Вызывает сильную боль</li>
						<li>Хронический. При систематическом разрушении зубов.</li>
					</ul>
					<p>При лечении кариеса полость в зубе зачищается кариозная полость и каналы, удаляются пораженные кариесом участки  посредством ультразвуковой машинки, которая ускоряет процесс. После обработки место закрывается пломбой. Перед началом манипуляций стоматолог терапевт может сделать вам анестезию, если это необходимо для обезболивания. Так что лечение будет без неприятных для вас ощущений.</p>
					<p>При пломбировании стоматолог может предложить вам такие виды пломбировочных материалов:</p>
					<ul>
						<li>Металл. Используется под коронки.</li>
						<li>Стеклоиономерные цементы. Достаточно дешевые и обеспечивающие хорошее прилегание к зубу материалы. Они дополнительно питают зубную ткань фтором и не допускают развития повторного кариеса. При этом материалы эти достаточно хрупкие – быстро истираются.</li>
						<li>Химически отверждеваемые композиты (фарфоровые).В их состав входят акрилосодержащие, эпоксидные или светоотверждаемые материалы. Пломбы наименее токсичны, но могут вызывать риск повторного кариеса.</li>
						<li>Светоотверждаемые композиты (гелиоотверждаемые и фотополимерные). Прочные и эстетичные. При умелой работе они практически не заметны. При этом материал может давать усадку, что приведет к необходимости замены пломбы через 5-7 лет.</li>
					</ul>
					<p>Выбор пломбировочного материала повлияет на конечную цену лечения кариеса.</p>
					<h2 className={styles.title}>Когда нужно обращаться к стоматологу для лечения кариеса</h2>
					<p>Излишне будет говорить, что профилактический осмотр в кабинете стоматолога проводится раз в полгода. Это избавит вас от любых проблем в полости рта. Если же вы не придерживаетесь данного правила, то визит в стоматологическую клинику нельзя откладыватье, если у вас появляется:</p>
					<ul>
						<li>Острая зубная боль</li>
						<li>Чувствительность на горячее, холодное, сладкое</li>
						<li>Тянущие неприятные ощущения в области зуба</li>
					</ul>
					<p>Стоматологи нашей клиники в Подольске быстро обнаружат проблему и окажут вам необходимое лечение кариеса.
Записаться на прием вы можете по телефону или заполнив форму на нашем сайте.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Karies;
