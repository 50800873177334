import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './lechenie-micro.module.scss';

function LechenieMicro() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Лечение зубов под микроскопом: заметен каждый микромиллиметр</h1>
						<p>Стоматология «Дантист Плюс» не случайно пользуется популярностью среди пациентов не только Подольска, но и Москвы, а также других близлежащих городов. Ведь своим пациентам мы можем предложить помимо низких цен еще и высокоточное лечение, которое производится с использованием инновационных технических разработок.</p>
						<p>У нас есть специально обустроенные кабинеты, где вам предложат лечение зубов под микроскопом в Подольске. Скажем честно, не многие стоматологии могут похвастаться такой инновацией.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Что дает лечение зубов под микроскопом пациенту?</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Представьте, вам пролечили зуб, а через день или два боль возобновилась. Плохо пролечили? Выполнили некачественно свою работу?</p>
							<p>Вовсе нет – болезненные ощущения связаны с началом разрушения соседнего зуба, прикорневой кариес в котором стоматолог просто не заметил. Ведь у него не было микроскопа.</p>
							<p>Но теперь у нас есть этот прибор, а потому каждый наш пациент будет ходить с улыбкой долгое время, выйдя из нашей клиники.</p>
						</div>
					</div>
					<p className={styles.bold_text}>Стоимость лечение зубов под микроскопом в стоматологии «Дантист Плюс» такая же, как при лечении традиционным способом.</p>
					<p>Это лишь один пример, а ведь проблем, которые не видны даже самому опытному стоматологу, гораздо больше.</p>
					<p>Вот почему лечение зубов под микроскопом помогает рассмотреть:</p>
					<ul>
						<li>Микротрещинки зуба</li>
						<li>Появляющиеся белые пятна, требующие нанесения укрепляющего защитного покрытия (фторизации)</li>
						<li>Микродырочки, которые следует пролечить на начальной стадии, чтобы не допустить разрушения зуба</li>
					</ul>
					<p>Кроме того, использование микроскопа в процессе работы дает возможность стоматологу:</p>
					<ul>
						<li>Изучить разветвление каналов и качественно очистить их</li>
						<li>Максимально точно подготовить зуб к пломбированию и наложить пломбировочный материал</li>
						<li>Вытащить малозаметное инородное тело из десны (например, рыбную косточку)</li>
						<li>Своевременно обнаружить трещины в зубе</li>
						<li>Лечить гранулему и кисту</li>
						<li>Профессионально осуществить микропломбирование</li>
						<li>Спасти «безнадежный зуб» благодаря практически «ювелирной» работе</li>
					</ul>
					<div className={cn(styles.wrapper_desc, styles.wrapper_desc_revers)}>
						<div className={cn(styles.wrapper_image, styles.wrapper_image_revers)}>
							<h2 className={styles.title_image}>Как осуществляется лечение с использованием стоматологического микроскопа</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Для работы с микроскопом в нашей стоматологической клинике оборудованы специальные кабинеты. Пациент садится в удобное кресло, к его ротовой полости направляется микроскоп, через который врач производит осмотр.</p>
							<p>При этом во время приема с пациентом работают два специалиста: один обрабатывает зубную полость, а второй подает инструменты, следит за тем, насколько комфортно чувствует себя больной. Это повышает качество обслуживания, позволяет максимально снизить какие-либо риски.</p>
						</div>
					</div>
					<h2 className={styles.title}>Микроскоп в стоматологии</h2>
					<ol>
						<li>Максимальная точность всех манипуляций. Это позволяет с одной стороны гарантировать максимальное качество работы врача-стоматолога, а с другой – обеспечивает максимальную сохранность вашего зуба.</li>
						<li>Ускоряет процесс проведения всех манипуляций. Действия врача становятся более уверенными, он не боится ошибиться, точно зная, как лечить канал или устанавливать пломбу, поэтому врач может быстрее закончить свою работу.</li>
					</ol>
					<p>Таким образом, лечение зубов с микроскопом обеспечивает комфортные условия для врача и пациента. И теперь для этого совершенно не обязательно ехать в Москву. «Дантист Плюс» предлагает услугу лечения под микроскопом в своей клинике в Подольске. И если вы хотите, чтобы ваша ротовая полость была в полном порядке, то приходите к нам, как для лечения зубов, так и в целях профилактического осмотра, чтобы сохранить здоровую улыбку.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default LechenieMicro;
