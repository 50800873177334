import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-scroll';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import Header from '../header/header';
import Review from '../review/review';
import OrderBottom from '../order-bottom/order-bottom';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import { reviews } from '../../const';
import qr from '../../images/qr.png';
import styles from './reviews-page.module.scss';

function ReviewsPage() {

  const [count, setCount] = useState(5);

  const refCaptcha = useRef();

  const form = useRef();

  const [toSend, setToSend] = useState({
    name: '',
    phone: '',
    review: '',
  });

  const [error, setError] = useState(false);
  const [shake, setShake] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = refCaptcha.current.getValue();

    const params = {
      ...toSend,
      'g-recaptcha-response': token,
    };

    emailjs.send('service_pms02up', 'template_6c0uzb7', params, 'P5MeWMgi8ntcxZNm2', 'g-recaptcha-response')
      .then((result) => {
        e.target.reset();
        window.grecaptcha.reset();
        setSuccess(true);
        setError(false);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }, (error) => {
        setError(true);
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 1000);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <div className={cn(styles.reviews, 'container')}>
          <h2 className={styles.title}>Здесь Вы можете <Link activeClass="active" className={styles.link} to="review" spy smooth duration={500} >оставить свой отзыв</Link></h2>
          {
            [...reviews].reverse().slice(0, count).map((review) => (
              <Review review={review} key={review.id} />
            ))
          }
          {reviews.length > count ? <button className={styles.button} name="review" onClick={() => setCount(count + 5)}>Показать еще</button> : ''}
          <div className={shake ? cn(styles.send, styles.shake) : styles.send}>
            <h3 className={styles.title}>Ваш отзыв</h3>
            <form ref={form} onSubmit={handleSubmit} className={styles.form} >
              <div className={styles.labels}>
                <label className={styles.label}>
                  <input onChange={handleChange} type="text" name="name" placeholder="Имя" aria-label="Имя" className={styles.input} required></input>
                </label>
              </div>
              <label className={styles.labels}>
                <textarea onChange={handleChange} className={styles.textarea} name="review" placeholder="Ваш комментарий" aria-label="Отзыв" rows="5" required></textarea>
              </label>
              <p className={error ? styles.error : cn(styles.error, styles.error_none)}>* Заполните все поля</p>
              <div className={styles.captcha}>
                <ReCAPTCHA ref={refCaptcha} sitekey="6LcrMJcjAAAAABTrEZjoVeuLABlfpFaj-EWknSYv" />
              </div>
              <div className={success ? styles.success : cn(styles.success, styles.success_none)}>Форма успешно отправлена</div>
              <button className={styles.button_form} type="submit" aria-label="Отправить">Отправить</button>
            </form>
          </div>
        </div>
        <div className={styles.qr}>
          <a href='https://yandex.ru/profile/1084471890?intent=reviews&utm_source=qr&utm_medium=triangle_vertical&utm_campaign=v2' target="_blank" rel="noreferrer">
            <img className={styles.photo} src={qr} alt='Сазонова Светлана Сергеевна' width="722" height="1644" />
          </a>
        </div>
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default ReviewsPage;
