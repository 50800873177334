import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './microprotez.module.scss';

function Microprotez() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Микропротезирование зубов - изготовление и цена в Подольске</h1>
						<p>Микропротезирование зубов, является достижением современной ортопедической стоматологии, основной целью которого стало максимальное снижение стоматологического воздействия на соседние здоровые опорные зубы при процедуре протезирования зубов.</p>
						<p>Перед проведением протезирования зубов, люминирования или имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Микропротезы изготавливаются в лаборатории с высокой точностью.</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Вкладки и накладки, которые используют при микропротезирвании, внешним видом напоминают обычные пломбы.</p>
							<p>Материалы используемые при микропротезировании позволяют восстановить зуб и тем самым сделать его практически неотличимым от обычного зуба.</p>
							<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление вкладок и накладок для микропротезирования в кротчайшие сроки. Это долговечный способ забыть о проблемах с зубами!</p>
						</div>
					</div>
					<p>Вам понравится цена зубного микропротезирования, ведь мы исходим из конкретной ситуации. Вы останетесь довольны стоимостью и качеством.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
					<p>Микропротезирование незаменимо при реставрации передних зубов и скрытии дефектов формы зубов или зубного ряда.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Microprotez;
