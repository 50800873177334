import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { AppRoute } from "../../../const";
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './otbelivanie-zubov.module.scss';

function Otbelivanie() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Отбеливание зубов – выглядеть совершенно</h1>
						<p>Стоматология «Дантист Плюс» предлагает отбеливание зубов в Подольске по низкой цене. Мы используем инновационные методы чистки зубов, что позволяет получить высокий результат. У нас работают только дипломированные специалисты, которые готовы подсказать, какой метод отбеливания лучше выбрать, чтобы получить требуемый результат.</p>
						<p>Наши стоматологи всегда готовы подобрать наиболее щадящий метод отбеливания зубов каждому пациенту. Если у вас есть какие-либо противопоказания к препаратам или манипуляциям, то не забудьте сообщить об этом стоматологу.Он сможет найти метод чистки зубов, который не только подарит белизну, но и сохранит ваше здоровье без каких-либо рисков.</p>
						<p>К любому процессу мы подходим комплексно, поэтому чистка будет частью профилактики заболеваний ротовой полости, что станет залогом вашего здоровья.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Для чего делать отбеливание зубов</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Большинство пациентов стоматологической клиники «Дантист Плюс» обращаются за услугой отбеливания зубов для того, чтобы придать улыбке белоснежный вид. Однако мы можем сказать, что данная процедура не только эстетическая. Ведь в большинстве случаев приходится удалять зубной налет, зубной камень, которые образуются из-за ненадлежащей гигиены полости рта, частом потреблении кофе, курения.</p>
							<p>Таким образом, отбеливание позволяет позаботиться о состоянии полости рта, предотвращает развитие кариеса, пародонтоза, иных заболеваний. Так что, запланировав данную процедуру, вы делаете шаг к своему здоровью.</p>
						</div>
					</div>
					<h2 className={styles.title}>Виды отбеливания зубов</h2>
					<ul>
						<li><Link
							to={AppRoute.AIR_FLOW}
							className={styles.link}>
						Air Flow.</Link> По сути это чистка зубов, которая позволяет вернуть им естественный цвет. Эффекта отбеливания в данном случае особого нет. А вот налет и зубной камень снимается данной системой очень быстро и достаточно эффективно.</li>
						<li><Link
							to={AppRoute.ULTRA_CHISTKA}
							className={styles.link}>
						Ультразвуковое отбеливание</Link> зубов оказывает следующие действия, эффективно удаляет зубной камень, осуществляется путем полировки использование специального ультразвукового скипидара и воды а также медицинского порошка глубоко проникающего в эмаль. Данный метод позволяет эффективно восстановить естественный цвет зубов,  визуально создается эффект отбеливания.</li>
						<li>Химическое отбеливание осуществляется с использованием хлоридов водорода и карбамида. Дает возможность отбелить зубы на 5 и более лет . Для данного действия необходимо предварительное удаление камней.</li>
						<li>Лазерное отбеливание производится с предварительным очищения зубной эмали посредством специального химического состава. Послеэтого хирург-стоматолог воздействует специальным прибором с лазерным излучением . В результате данной процедуры за 60 минут удается отбелить зубы в 5-10 раз. Метод более дорогостоящий однако весьма эффективный.</li>
						<li>Фотоотбеливание представляет собой процесс глобализации специального кислородного составы галогенового света. При проведении процедуры удается устранить темные пятна на зубах, они только очистить поверхностный слой. Данный метод подходит для людей с чувствительной эмалью. При умеренном потреблении пациентам чай и кофе эффект может сохраняться в течение нескольких лет.</li>
						<li>Трансформативное отбеливание позволяет эффективно удалить любой налет, а также зубной камень посредством воздействия абразивных веществ. По окончании процедуры необходимо провести сеанс восстановление эмали . Достигнут эффект сохраняется несколько лет.</li>
					</ul>
					<p>Выбор методов отбеливания зубов в стоматологической клинике «Дантист Плюс» в Подольске осуществляется на основе рекомендаций лечащего врача, а также пожеланий самого пациента. Перед началом проведения каких-либо манипуляций вам будет произведена полная диагностика полости рта, согласованы выбранные методы отбеливания.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Otbelivanie;
