import { createAction } from '@reduxjs/toolkit';

const ActionsType = {
	ACTIVE_PAGE: 'data/activePage',
};

const changeActivePage = createAction(ActionsType.ACTIVE_PAGE, (payload) => ({
	payload,
}));

export { changeActivePage };