import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './lechenie-desen.module.scss';

function LechenieDesen() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Лечение болезней десен в стоматологии - цена</h1>
						<p>Лечение десен начинают с тщательного обследования полости рта пациента. Перед проведением лечения десен, удаления зуба или имплантации зубов, рекомендуется процедура чистки зубов.</p>
						<p>Лечение десен, зачастую, является комплексом стоматологических процедур с применением различных методов.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Типы лечения десен</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p><span className={styles.bold_text}>Общая терапия при лечении десен</span> – это применении поливитаминов, антибиотиков и иммуностимуляторов.</p>
							<p><span className={styles.bold_text}>Хирургические методы лечения десен</span> – это кюретаж, удаление десневых сосочков, частичное и полное иссечение края десны, а так же пластика десны.</p>
							<p><span className={styles.bold_text}>Ортопедические методы лечения десен</span> – это шинирование и протезирование зубов.</p>
							<p><span className={styles.bold_text}>Физические методы лечения десен</span> – это электрофорез, микроволновая терапия, дарсонвализации, гипербарооксигенотерапия, лазерная терапии.</p>
						</div>
					</div>
					<p>Вам понравится стоимость лечения десен зуба, ведь мы исходим из конкретной ситуации. Вы останетесь довольны ценой и качеством.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default LechenieDesen;
