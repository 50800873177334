import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './parodontit.module.scss';

function Parodontit() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Пародонтит зубов лечение</h1>
						<p>Пародонтитом называют воспаление тканей, окружающих зубы.</p>
						<p>Пародонтитом страдают около 85% мужчин и женщин в возрасте от 35 лет.</p>
						<p>Пародонтит разделяют на острый и хронический. Острый парадонтит случается реже.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Причины парадонтита:</h2>
						</div>
						<div className={styles.wrapper_text}>
							<ul>
								<li>скопление микробов</li>
								<li>аномалии прикуса</li>
								<li>неправильное положение отдельных зубов</li>
								<li>травмы</li>
								<li>низкое содержание минеральных веществ в зубах</li>
								<li>употребление в пищу только очень мягкой еды</li>
								<li>экология</li>
							</ul>
						</div>
					</div>
					<p>Лечится пародонтит комплексно, обязательно включая хирургическое или не хирургическое лечение зубодесневых карманов.</p>
					<p>Вам понравится стоимость лечения пародонтита зуба, ведь мы исходим из конкретной ситуации. Вы останетесь довольны ценой и качеством</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением и по возможности сохранить зуб. </p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Parodontit;
