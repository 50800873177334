import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './breketi.module.scss';

function Breketi() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn('container', styles.usluga)}>
					<div className={styles.main_text}>
            <h1 className={styles.main_title}>Брекеты – шаг к безупречной улыбке</h1>
						<p>Для кого-то голливудская улыбка – мечта, но для специалистов клиники «Дантист Плюс» нет ничего невозможного, поэтому, если вы обратитесь в нашу стоматологию, то мы порекомендуем вам установить брекет системы в том случае, если проблема заключается в неправильном прикусе и неровных зубах. </p>
						<p>Решение об установке тех или иных брекетов врач принимает вместе с пациентом, что дает вам возможность ориентироваться не только по виду устанавливаемой конструкции, но и по цене.</p>
						<p>Мы находимся в Подольске, поэтому можем предложить свои услуги по лучшим ценам не только для жителей нашего города, но и для жителей Москвы.  Предварительно вы можете записаться на прием к врачу ортодонту по телефону или оставив заявку на нашем сайте.</p>
          </div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Установка брекетов в клинике «Дантист Плюс»</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Решив установить брекеты в нашей клинике в Подольске, вы должны записаться на прием к врачу ортодонту, который проведет обследование вашей ротовой полости, оценит состояние зубов.</p>
							<p>Дополнительно вы можете быть направлены на рентгеновское исследование, что позволит избежать проблем ношения брекетов.</p>
							<p>После того, как вся диагностика проведена, вам будут предложены различные варианты брекетов, которые возможны к установке в вашем конкретном случае.</p>
							<p>Вы можете быть уверены, что любая брекет-система будет адаптирована под особенности вашей ротовой полости, что позволит избежать дискомфорт во время приема пищи, разговора и прочих повседневных действий.</p>
						</div>
					</div>
					<h2 className={styles.title}>Для чего нужны брекеты</h2>
					<p>Многие полагают, что брекеты лишь исправляют прикус и делают зубы идеально ровными. Однако это не совсем так, ведь неровные зубы являются фактором нарушения обмена веществ в организме, приводит к головным болям, депрессиям, нарушению работы желудочно-кишечного тракта.  Поэтому если у вас есть проблемы с прикусом, то следует незамедлительно обратиться к врачу ортодонту.</p>
					<h2 className={styles.title}>Брекеты: разберемся в понятиях</h2>
					<p>Брекеты представляют собой небольшие тонкие пластины, которые крепятся на зубы посредством тонкой проволоки.  Они позволяют решить проблему неровности зубов как врожденные, так и приобретенные вследствие определенных травм, механических повреждений.  Любая брекет-система подбирается индивидуально врачом ортодонта, учитывая состояние ваших зубов, их расположение (расстояние между зубами), неровности (прикус).</p>
					<p className={styles.bold_text}>Брекеты могут быть:</p>
					<ul>
						<li>Металлические наиболее надежный и недорогой вариант, который часто устанавливают детям и подросткам. Металл позволяет хорошо фиксировать зубы, что дает возможность быстро изменить прикус. При этом данный материал достаточно надежен и долговечен - у вас не будет проблем с уходом за полостью рта. Металлические брекеты, в свою очередь, подразделяются на фигурные скобки, золотые скобы, саморегулирующиеся брекеты, систему Damon. Наряду с положительными свойствами металлические брекеты имеют и свои недостатки, в первую очередь это не вполне эстетичный вид, а также некоторые пациенты жалуются на неудобство во время установки эксплуатации таких брекетов. Кроме того, при неверно выбранном материале металл может линять, окрашивая зубы. Специалисты нашей стоматологии стараются избежать этих недостатков, подбирая только качественные недорогие металлические брекеты.</li>
						<li>Керамические позволят изменить прикус так же эффективно, как и металлические скобы. При этом керамические брекеты практически не видны на зубах. Однако их не рекомендуется ставить людям, которые курят и употребляют много кофе, поскольку керамика может окрашиваться.</li>
						<li>Сапфировые - более дорогой вариант. Схожие с керамическими брекетами, которые практически незаметны на зубах. Не подходят людям, имеющим вредные привычки, а также предпочитающим часто пить чай или кофе, поскольку сапфировые брекеты могут окрашиваться.</li>
						<li>Пластиковые совершенно невидимы, однако подходят лишь пациентам с незначительными нарушения прикуса, поскольку не оказывают большого давление на зубы. Такой вариант прост в уходе и не вызывает болезненных ощущений, не окрашивает зубы. Из пластика изготавливаются лишь съемные брекеты которые следует снимать во время приема пищи, но не забывать надевать после этого. При этом носить пластиковые брекеты следует не менее 22 часов в сутки. Полиуретан, входящий в состав данного варианта, может вызывать аллергию.</li>
						<li>Лингвальные - идеальный вариант для тех, кто стесняется устанавливать брекеты, но при этом хочет получить голливудскую улыбку. Данный вариант устанавливается сзади зубов, соответственно,  брекет систему абсолютно не видно. Вам нравится такой вариант? Тогда учтите, что при ношении лингвальных брекетов могут возникать болезненные ощущения во время приема пищи, а также некоторые затруднения во время разговора.  Лингвальная брекет-система достаточно дорогая и не может быть установлена при значительных проблемах с прикусом.</li>
						<li>Титановые. Цена достаточно высока, поэтому мы рекомендуем устанавливать данный вариант только людям страдающим аллергическими реакциями на иные материалы. Кроме того, титановые брекеты следует устанавливать людям, которые имеются в ротовой полости импланты из этого же материала.</li>
					</ul>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default Breketi;
