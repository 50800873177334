import {
  startDate,
  yearDecl,
  monthDecl,
  dayDecl,
  hourDecl,
  minuteDecl,
  seconsDecl
} from './const.js';
import { plural } from './utils.js';

function diffSubtract(date1, date2) {
  return date2 - date1;
}

const startDateToString = `${startDate.full_year}-${startDate.month}-${startDate.day}T${startDate.hours}:${startDate.minutes}:${startDate.seconds}`;

const getTime = (display) => {
  const now = new Date();
  const date = new Date(startDateToString);
  const difference = diffSubtract(date, now);
  const result = new Date(difference);

  const year = result.getUTCFullYear() - 1970;
  const month = result.getUTCMonth();
  const day = result.getUTCDate() - 1;
  const hour = result.getUTCHours();
  const minute = result.getUTCMinutes();
  const second = result.getUTCSeconds();

  const strTimerFull = `Нам сейчас - ${year} ${plural(year, yearDecl)} : ${month} ${plural(month, monthDecl)} : ${day} ${plural(day, dayDecl)} : ${hour} ${plural(hour, hourDecl)} : ${minute} ${plural(minute, minuteDecl)} : ${second} ${plural(second, seconsDecl)} `;

  const strTimer = `Нам сейчас - ${year} ${plural(year, yearDecl)} : ${month} ${plural(month, monthDecl)} : ${day} ${plural(day, dayDecl)}`;

  switch (display) {
    case true:
      return strTimer;
    case false:
      return strTimerFull;
    default:
      return strTimerFull;
  }
};

export { getTime };
