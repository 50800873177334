import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { AppRoute } from "../../../const";
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './ultrazvukovaya-chistka.module.scss';

function UltraChistka() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Ультразвуковая чистка зубов в Подольске</h1>
						<p>Ультразвуковая чистка – это современный и безопасный способ профессиональной чистки зубов, с которого должно начинаться любое лечение в полости рта.</p>
						<p>С помощью специального аппарата (скалера), с поверхности зубов удаляется налет и зубной камень, очень деликатно, не нанося вреда.</p>
						<p>Ультразвуковая чистка избавляет от любых зубных отложений и полностью очищает эмаль, восстанавливая природный цвет.</p>
					</div>
					<p>Ультразвуковая чистка зубов дополняет профилактическую процедуру <Link
							to={AppRoute.AIR_FLOW}
							className={styles.link}>
						Air Flow.</Link> Главным плюсом ультразвуковой чистки, является то, что она абсолютно безболезненна!</p>
					<p>Вас обязательно порадует стоимость ультразвуковой чистки зубов в стоматологии «Дантист Плюс»! Вы останетесь довольны своими зубами.</p>
					<p>В процессе профессиональной гигиены полости рта  врачу становиться легче выявить патологические процессы на начальных стадиях.</p>
					<p>Вовремя удаленные наддесневые и поддесневые отложения, избавляют пациента от частого лечения зубов, десен и пародонта в целом.</p>
					<p>В стоматологии «Дантист Плюс» не травмируя ткани зуба проводят их чиску от различных отложений.</p>
					<p>После процедуры профессиональной чистки зубы становятся гладкими, чистыми и красивыми на долго!</p>
					<p>В стоматологии «Дантист Плюс» также можно провести полировку поверхности зубов для пердотвращения дальнейшего прилипания налета.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default UltraChistka;
