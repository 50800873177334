import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './kultevaya-vkladka.module.scss';

function KultVkladka() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Восстановление зуба культевой вкладкой - изготовление и цена в Подольске</h1>
						<p>В отличае от штифтов, культевая вскладка распределяет нагрузку на всю поверхность зуба одинаково, так как она устойчиво закрепляется во всех корневых каналах.</p>
						<p>Сначала врач-стоматолог обязательно проверит состояние корня зуба. Ведь применять культевые вкладки можно только при надежности корневой ткани.</p>
						<p>Длина корня должна быть ни меньше высоты устанавливаемой коронки, а стенки должны быть не менее 1 мм толщиной, чтобы не треснули при давлении. Оставшиеся стенки стачиваются до 1-2 мм над десной.</p>
						<p>Перед проведением различных видов стоматологических процедур, таких как протезированием и имплантации зубов, рекомендуется процедура чистки зубов.</p>
					</div>
					<div className={styles.wrapper_desc}>
						<div className={styles.wrapper_image}>
							<h2 className={styles.title_image}>Культевая вкладка – незаменима при восстановлении сильно разрушенных зубов.</h2>
						</div>
						<div className={styles.wrapper_text}>
							<p>Чтобы зафиксировать культевую вкладку в каналах корня используют различные виды композитных цементов. Цементирующее вещество вводят в каналы и наносят на штифты, затем вкладку вставляют в подготовленные отверстия и крепко фиксируют.</p>
							<p>Стоматологическая клиника «Дантист Плюс» выполняет изготовление культевых вкладок в кротчайшие сроки. Восстановление зуба в нашей стоматологии - это долговечный способ забыть о проблемах с зубами!</p>
						</div>
					</div>
					<p>Вам понравится стоимость зубной вкладки, ведь мы исходим из конкретной ситуации. Вы останетесь довольны ценой и качеством.</p>
					<p>Наличие современной аппаратуры позволяет поставить точный диагноз и осуществить контроль за лечением.</p>
					<p>Коронку подготавливают уже в следующем посещении стоматологии.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default KultVkladka;
