import React from 'react';
import cn from 'classnames';
import Header from '../header/header';
import OrderBottom from '../order-bottom/order-bottom';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import image from '../../images/contacts.jpg';
import styles from './contacts-page.module.scss';

function ContactsPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className='visually-hidden'>Контакты стоматологии «Дантист Плюс» в Подольске</h1>
        <div className={cn(styles.contacts, 'container')}>
          <section className={styles.wrap}>
            <h2 className='visually-hidden'>Адрес и время работы стоматологии «Дантист Плюс» в Подольске</h2>
            <div className={styles.contact}>
              <div className={styles.phones}>
                <h3>Телефоны:</h3>
                <a className={cn(styles.link, styles.phone)} href="tel:+74967524444">+7 (4967) 52-44-44</a>
                <a className={cn(styles.link, styles.phone)} href="tel:+79651978020">+7 (965) 197-80-20</a>
                <h3>Время работы:</h3>
                <p>Пн-Пт: 10:00-21:00</p>
                <p>Сб: 10:00-20:00</p>
                <p>Вс: 10:00-17:00</p>
                <h3 className={styles.title}>Соц. сети</h3>
                <a className={cn(styles.link, styles.vk)} href="https://vk.com/stomatologiya_v_podolske" target="_blank" rel="noreferrer">Vk</a>
                <a className={cn(styles.link, styles.instagram)} href="https://www.instagram.com/dantplus_podolsk/" target="_blank" rel="noreferrer">Instagram</a>
                <a className={cn(styles.link, styles.whatsapp)} href="https://wa.me/79651978020" target="_blank" rel="noreferrer">Whatsapp</a>
                <a className={cn(styles.link, styles.telegram)} href="https://t.me/+79651978020" target="_blank" rel="noreferrer">Telegram</a>
              </div>
              <div>
                <p>Стоматологическая поликлиника «Дантист Плюс» находится в центре Подольска по адресу ул. Кирова, 61.</p>
                <p>Доехать к нам из Москвы можно пригородной электричкой с Курского вокзала до станции Кутузовская или станции Подольск, а оттуда воспользоваться общественным транспортом:</p>
                <ul>
                  <li>От станции Подольск автобусом 10 или 14 до остановки «Сквер Подольских Курсантов» (4 остановки).</li>
                  <li>Троллейбусом 1, 2, 3</li>
                </ul>
                <p>Из Москвы до стоматологической поликлиники доехать также можно:</p>
                <ul>
                  <li>На автобусе 417, 406, 407, 462 со станции метро «Южная».</li>
                  <li>На автомобиле по Варшавскому или Симферопольскому шоссе.</li>
                </ul>
              </div>
            </div>
            <img src={image}></img>
          </section>
        </div>
        <OrderBottom />
        <YandexMap />
      </main>
      <Footer />
    </div>
  );
}

export default ContactsPage;
