import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { AppRoute } from '../../const';
import styles from './department.module.scss';

function Department() {

  return (
    <div className={styles.department}>
      <div className={cn(styles.section, styles.section_left)}>
        <div className={cn(styles.section_wrapper, styles.section_wrapper_left)}>
          <h2 className={styles.title}>Взрослая стоматология</h2>
          <p className={styles.text}>Профиссиональное и доступное лечение ваших зубов.</p>
          <div className={styles.button_wrapper}>
            <Link to={AppRoute.ORDER} className={cn('button', 'button_light')}>Записаться</Link>
            <Link to={AppRoute.ADULT} className={cn('button', 'button_dark')}>Перейти</Link>
          </div>

        </div>
      </div>
      <div className={cn(styles.section, styles.section_right)}>
        <div className={cn(styles.section_wrapper, styles.section_wrapper_right)}>
          <h2 className={styles.title}><span className={styles.pink}>Д</span><span className={styles.yellow}>е</span><span className={styles.green}>т</span><span className={styles.pink}>с</span><span className={styles.blue}>к</span><span className={styles.yellow}>а</span><span className={styles.red}>я</span> <span className={styles.yellow}>с</span><span className={styles.red}>т</span><span className={styles.blue}>о</span><span className={styles.pink}>м</span><span className={styles.yellow}>а</span><span className={styles.green}>т</span><span className={styles.pink}>о</span><span className={styles.yellow}>л</span><span className={styles.red}>о</span><span className={styles.green}>г</span><span className={styles.pink}>и</span><span className={styles.blue}>я</span></h2>
          <p className={cn(styles.text, styles.text_right)}>С заботой об улыбках ваших детей без боли и слёз</p>
          <div className={styles.button_wrapper}>
            <Link to={AppRoute.ORDER} className={cn('button', 'button_light')}>Записаться</Link>
            <Link to={AppRoute.KIDS} className={cn('button', 'button_dark')}>Перейти</Link>
          </div>
        </div>
      </div>
    </div >

  );
}

export default Department;
