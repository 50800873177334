import { createReducer } from '@reduxjs/toolkit';
import { changeActivePage } from './action';

const initialPage = window.location.pathname;

const initialState = {
	isActivePage: initialPage,
};

const data = createReducer(initialState, (builder) => {
	builder
		.addCase(changeActivePage, (state, action) => {
			state.isActivePage = action.payload;
		});
});

export { data };