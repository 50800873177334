import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header/header';
import OrderBottom from '../order-bottom/order-bottom';
import YandexMap from '../yandex-map/yandex-map';
import Footer from '../footer/footer';
import ScrollButton from '../scroll-to-top/scroll-to-top';
import styles from './doctor.module.scss';

function Doctor({ doctor }) {

  const { name, speciality, experience, diplomas, text, image } = doctor;

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
        <h1 className='visually-hidden'>Акции и скидки в стоматологии «Дантист Плюс» в Подольске</h1>
        <div className='container'>
          <ul className={styles.list}>
            <li className={styles.item}>
              <div className={styles.container}>
                <div className={styles.description}>
                  <h3 className={styles.name}>{name}</h3>
                  <p className={styles.speciality}>{speciality}</p>
                  <p className={styles.experience}>{experience}</p>
                  <ul className={styles.diplomas}>
                    {diplomas.map((diplom) => (
                      <li className={styles.diploma} key={diplom}>
                        {diplom}
                      </li>
                    ))}
                  </ul>
                  <p className={styles.text}>{text}</p>
                  {/* <Link to={AppRoute.ORDER} className={styles.button}>Записаться</Link> */}
                </div>
                <img className={styles.image} src={image} alt='Понизова Нина Викторовна' width="1200" height="1800" />
              </div>
            </li>
          </ul>
        </div>
        <OrderBottom />
        <YandexMap />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

Doctor.propTypes = {
  doctor: PropTypes.shape({
    name: PropTypes.string.isRequired,
    speciality: PropTypes.string.isRequired,
    experience: PropTypes.string.isRequired,
    diplomas: PropTypes.arrayOf(PropTypes.string.isRequired),
    text: PropTypes.string.isRequired,
    image: PropTypes.node.isRequired,
  }),
};

export default Doctor;
