import React from 'react';
import cn from 'classnames';
import Header from '../../header/header';
import OrderBottom from '../../order-bottom/order-bottom';
import YandexMap from '../../yandex-map/yandex-map';
import ScrollButton from '../../scroll-to-top/scroll-to-top';
import Footer from '../../footer/footer';
import styles from './air-flow.module.scss';
import image from '../../../images/chistka-air-flow.jpg';

function AirFlow() {

  return (
    <div className={styles.wrapper}>
      <Header />
      <main>
				<div className={styles.image}></div>
				<div className={cn(styles.usluga, 'container')}>
					<div className={styles.main_text}>
						<h1 className={styles.main_title}>Чистка зубов методом Air-Flow в Подольске</h1>
						<p>Airflow - это один из самых безопасных и быстрых методов удаления зубных отложений.</p>
						<p>Такую процедуру отбеливания зубов точнее было бы назвать косметической очисткой, так как в процессе использования Air flow происходит очищение эмали от налета, зубных отложений и различных бактерий.</p>
					</div>
					<p>Выравнивается поверхность зубов, благодаря мягкой шлифовке воздушной струей и специального порошка. При этом процедура не вредит зубам. По окончанию эмаль покрывается защитным лаком.</p>
					<img src={image}></img>
					<p>После данного метода цвет зубов становится намного светлее, чем до использования Air flow. Происходит и очищение за одну стоимость.</p>
					<p>В процессе профессиональной гигиены полости рта врачу становиться легче выявить патологические процессы на начальных стадиях.</p>
					<p>В Подольске самая низкая стоимость отбеливания зубов методом Air-flow в стоматологии Дантист плюс! Вы останетесь довольны своими зубами.</p>
					<p>Вовремя удаленные наддесневые и поддесневые отложения, избавляют пациента от частого лечения зубов, десен и пародонта в целом.</p>
					<p>В стоматологии «Дантист Плюс» не травмируя ткани зуба проводят их чиску от различных различных отложений, а именно: зубной налет от пищевых красителей (чай, кофе), налет от курения.</p>
					<p>После процедуры профессиональной чистки зубы становятся гладкими, чистыми и красивыми на долго!</p>
					<p>В стоматологии «Дантист Плюс» также можно провести полировку поверхности зубов для пердотвращения дальнейшего прилипания налета.</p>
				</div>
				<OrderBottom />
				<YandexMap />
				<ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default AirFlow;
