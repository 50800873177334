import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { useMediaQuery } from 'react-responsive';
import cn from 'classnames';
import Service from '../service/service';
import 'swiper/css';
import 'swiper/css/navigation';
import styles from './services.module.scss';


SwiperCore.use([Autoplay, A11y, Navigation]);

function Services({ title, content }) {

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  const isTablet = useMediaQuery({
    query: '(min-width: 744px)',
  });

  return (
    <div className={cn(styles.services, 'container')}>
      <h2 className={styles.title}>{title}</h2>
      <Swiper
        // eslint-disable-next-line no-nested-ternary
        slidesPerView={isDesktop ? 3 : isTablet ? 2 : 1}
        spaceBetween={30}
        allowTouchMove={!isDesktop}
        a11y
        loop
        navigation
      >
        {
          content.map((service) => (
            <SwiperSlide key={service.id}>
              <Service service={service} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div >
  );
}

Services.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      tabName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Services;
